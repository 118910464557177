import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const Hero = () => {
  const [currentHeading, setCurrentHeading] = useState(0);

  const headings = [
    "Leading Digital Marketing Agency in India",
    "Accelerating Your Business Growth with Digital Marketing Strategies",
    "Empowering Your Business Through Innovative Digital Marketing Solutions",
  ];

  // Initialize AOS and set interval for changing heading
  useEffect(() => {
    AOS.init({ duration: 1000 });

    const headingInterval = setInterval(() => {
      setCurrentHeading((prevHeading) => (prevHeading + 1) % headings.length);
      AOS.refresh(); // Reinitialize AOS after changing the heading
    }, 3000); // Change heading every 3 seconds

    return () => clearInterval(headingInterval); // Cleanup interval on unmount
  }, [headings.length]);

  return (
    <div className="relative h-screen flex items-center justify-center overflow-hidden">
      {/* Add overflow-x-hidden to ensure no horizontal scroll */}
      <style>{`
        body {
          overflow-x: hidden;
        }
      `}</style>

      {/* Video Background */}
      <video
        className="absolute inset-0 w-full h-full object-cover"
        autoPlay
        loop
        playsInline
        muted
      >
        <source
          src="https://ik.imagekit.io/tdlebsr5e/3255275-uhd_3840_2160_25fps%20(1)%20(1)%20(1).mp4?updatedAt=1729679061317"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>

      {/* Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      {/* Hero Content */}
      <div
        className="relative z-10 text-white px-4 sm:px-8 md:px-16 lg:px-24 max-w-6xl text-center"
        data-aos="slide-right"
      >
        <h1
          className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-8"
          data-aos="zoom-in"
          key={currentHeading}
        >
          {headings[currentHeading]}
        </h1>
        <p className="text-base sm:text-lg md:text-xl mb-12">
          We are the Brand Builders for a Better Tomorrow!
        </p>
        <div>
          <Link to="/services">
            <button className="bg-gradient-to-r from-purple-300 to-pink-400 text-black px-6 py-3 rounded-lg text-lg font-bold hover:bg-lightpink transition duration-300">
              Explore Our Services
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Hero;
