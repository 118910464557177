import React, { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { Link } from 'react-router-dom';
import TestimonialWidget from '../Components/TesimonialWidget';

const testimonials = [
  {
    name: "Nancy Arora",
    title: "CEO",
    testimonial:
      "Working with Adbrusher Media has been a game changer for us. Their SEO strategies increased our website traffic by 150% in just three months! The team truly understands our needs and delivers results that exceed our expectations.",
    image: "https://randomuser.me/api/portraits/women/44.jpg",
  },
  {
    name: "Rajesh Chopra",
    title: "Marketing Manager",
    testimonial:
      "The creativity and expertise of this team are unmatched. Their social media campaigns not only boosted our brand visibility but also engaged our audience like never before. We saw a 40% increase in customer interactions within the first month!",
    image: "https://randomuser.me/api/portraits/men/44.jpg",
  },
  {
    name: "Emily Tiphny",
    title: "Owner",
    testimonial:
      "I can’t recommend Adbrusher Media enough! They transformed our online presence through effective content marketing and email campaigns. Our sales have increased significantly, and our customer loyalty has never been stronger.",
    image: "https://randomuser.me/api/portraits/women/45.jpg",
  },
  {
    name: "Mary Alice",
    title: "Founder",
    testimonial:
      "The PPC campaign designed by Adbrusher Media team brought immediate results. We achieved a remarkable ROI and were able to reach our target audience more effectively. Their attention to detail and strategic planning made all the difference.",
    image: "https://randomuser.me/api/portraits/women/46.jpg",
  },
  {
    name: "Saurabh Chawla",
    title: "Director",
    testimonial:
      "From website development to digital marketing strategies, Adbrusher Media has been with us every step of the way. Their dedication and innovative approach have helped us grow our online enrollment by 60%.",
    image: "https://randomuser.me/api/portraits/men/46.jpg",
  },
];

const TestimonialPage = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div>
      {/* Top Section with Background Image and Blue Tint */}
      <div
        className="relative h-1/2 py-32 flex items-center justify-start bg-cover bg-center px-4 sm:px-8 md:px-16 lg:px-32 lg:py-64"
        style={{
          backgroundImage: `url('https://ik.imagekit.io/7uve7qsipm/pexels-divinetechygirl-1181354.jpg?updatedAt=1727328203546')`,
        }}
        data-aos="slide-in"
      >
        <div className="absolute inset-0 bg-black bg-opacity-80"></div>
        <div className="absolute inset-0 bg-blue-800 bg-opacity-10"></div>

        <div className="relative z-10 text-left">
          <h1 className="text-4xl sm:text-5xl md:text-8xl text-white font-bold"
          data-aos="zoom-in-left"
          data-aos-anchor="#example-anchor"
          data-aos-offset="500"
          data-aos-duration="500">
            Portfolio
          </h1>
          <p className="text-white mt-6 font-semibold text-lg md:text-2xl">
            <Link to="/" className="text-yellowcustom hover:underline">
              Home
            </Link>{" "}
            / Portfolio
          </p>
        </div>
      </div>
      <TestimonialWidget/>
   </div>
  );
};

export default TestimonialPage;
