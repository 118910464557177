import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ContactForm from "../Components/ContactForm";
import AOS from "aos";
import "aos/dist/aos.css";

const isMobile = () => window.innerWidth <= 768;

const Company = () => {
  useEffect(() => {
    AOS.init({ once: true, offset: 50 }); // Initialize AOS with smoother animations
    window.addEventListener("scroll", AOS.refresh);
    return () => window.removeEventListener("scroll", AOS.refresh);
  }, []);

  return (
    <div className=" overflow-hidden flex flex-col">
      {/* Top Section with Larger Header */}
      <div
        className="relative h-1/2 py-32 flex items-center justify-start bg-cover bg-center px-4 sm:px-8 md:px-16 lg:px-32 lg:py-64"
        style={{
          backgroundImage: `url('https://images.pexels.com/photos/1181406/pexels-photo-1181406.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')`,
        }}
        data-aos="slide-in"
      >
        <div className="absolute inset-0 bg-black bg-opacity-80"></div>
        <div className="absolute inset-0 bg-blue-800 bg-opacity-10"></div>

        <div className="relative z-10 text-left">
          <h1 className="text-4xl sm:text-5xl md:text-7xl text-white font-bold"
            data-aos="zoom-in-left"
            data-aos-anchor="#example-anchor"
            data-aos-offset="500"
            data-aos-duration="500">
            Company
          </h1>
          <p className="text-white mt-6 font-semibold text-lg md:text-2xl">
            <Link to="/" className="text-yellowcustom hover:underline">
              Home
            </Link>{" "}/ Company
          </p>
        </div>
      </div>

      {/* First Section */}
      <div
        className="h-screen bg-white flex flex-col md:flex-row items-center"
        data-aos={!isMobile() ? "fade-up" : undefined}
      >
        <div className="w-full md:w-1/2 h-full flex items-center text-left justify-center p-8">
          <img
            src="https://ik.imagekit.io/6oa6qejxe/Screenshots/2147656715.jpg?updatedAt=1727773703717"
            alt="Company Overview"
            className="w-full h-auto shadow-xl"
            data-aos={!isMobile() ? "fade-up" : undefined}
          />
        </div>
        <div className="w-full md:w-1/2 h-full flex flex-col justify-center px-8">
          <h1 className="text-pink-500 font-bold text-base md:text-lg mb-2">
            ABOUT US
          </h1>
          <h2 className="text-3xl md:text-4xl font-bold mb-4">
            Our Vision & Mission: Empowering Brands in the Digital Age
          </h2>
          <p className="text-lg text-gray-700 mb-4">
            At Adbrusher Media, we envision a world where every brand can
            harness the power of digital marketing to connect authentically with
            their audience.
          </p>
        </div>
      </div>

      {/* Second Section */}
      <div
        className="h-screen bg-gray-100 flex flex-col md:flex-row-reverse items-center"
        data-aos="fade-up"
      >
        <div className="w-full md:w-1/2 h-full flex items-center justify-center p-8">
          <img
            src="https://ik.imagekit.io/6oa6qejxe/Screenshots/2516.jpg?updatedAt=1727777264846"
            alt="Services"
            className="w-full h-auto shadow-xl"
            data-aos={!isMobile() ? "fade-up" : undefined}
          />
        </div>
        <div className="w-full md:w-1/2 h-full flex flex-col justify-center px-8">
          <h1 className="text-pink-500 font-bold text-base md:text-lg mb-2">
            IDEA
          </h1>
          <h2 className="text-3xl md:text-4xl font-bold mb-4">
            Your Success is Our Priority
          </h2>
          <p className="text-lg text-gray-700 mb-4">
            Our mission is to empower businesses by delivering tailored digital
            marketing solutions that drive measurable results.
          </p>
        </div>
      </div>

      {/* Third Section */}
      <div
        className="h-screen bg-white flex flex-col md:flex-row items-center"
        data-aos="fade-up"
      >
        <div className="w-full md:w-1/2 h-full flex items-center justify-center p-8">
          <img
            src="https://ik.imagekit.io/6oa6qejxe/28011.jpg?updatedAt=1727776339619"
            alt="Our Mission"
            className="w-full h-auto shadow-xl"
            data-aos={!isMobile() ? "fade-up" : undefined}
          />
        </div>
        <div className="w-full md:w-1/2 h-full flex flex-col justify-center px-8">
          <h1 className="text-pink-500 font-bold text-base md:text-lg mb-2">
            ABOUT US
          </h1>
          <h2 className="text-3xl md:text-4xl font-bold mb-4">
            Core Values: The Heart of Adbrusher Media
          </h2>
          <p className="text-lg text-gray-700 mb-4">
            Integrity, Innovation, Collaboration, Excellence, and Growth are the
            core values that define our approach.
          </p>
        </div>
      </div>

      {/* Fourth Section */}
      <div
        className="h-screen bg-gray-100 flex flex-col md:flex-row-reverse items-center"
        data-aos="fade-up"
      >
        <div className="w-full md:w-1/2 h-full flex items-center justify-center p-8">
          <img
            src="https://ik.imagekit.io/6oa6qejxe/Screenshots/300.jpg?updatedAt=1727776495087"
            alt="Services"
            className="w-full h-auto shadow-xl"
            data-aos={!isMobile() ? "fade-up" : undefined}
          />
        </div>
        <div className="w-full md:w-1/2 h-full flex flex-col justify-center px-8">
          <h1 className="text-pink-500 font-bold text-base md:text-lg mb-2">
            DEALING
          </h1>
          <h2 className="text-3xl md:text-4xl font-bold mb-4">
            What We Deal In
          </h2>
          <p className="text-lg text-gray-700 mb-4">
            We specialize in Search Engine Optimization (SEO), Pay Per Click(PPC), social media marketing, content marketing, Digital Marketing
            and web development.
          </p>
        </div>
      </div>

      {/* Contact Form Section */}
      <div className=" bg-white flex items-center justify-center" data-aos="fade-up">
       
      </div>
    </div>
  );
};

export default Company;
