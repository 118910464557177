import React from "react";
import { Link } from "react-router-dom";

const isMobile = () => {
  return window.innerWidth <= 768; // Adjust this value based on your breakpoints
};

const FulfillmentPolicy = () => {
  return (
    <div>
      {/* Top Section with Background Image and Blue Tint */}
      <div
        className="relative h-1/2 py-32 flex items-center justify-start bg-cover bg-center px-4 sm:px-8 md:px-16 lg:px-32 lg:py-64"
        style={{
          backgroundImage: `url('https://ik.imagekit.io/7uve7qsipm/pexels-divinetechygirl-1181354.jpg?updatedAt=1727328203546')`,
        }}
        data-aos="slide-in"
      >
        <div className="absolute inset-0 bg-black bg-opacity-80"></div>
        <div className="absolute inset-0 bg-blue-800 bg-opacity-10"></div>

        <div className="relative z-10 text-left">
          <h1 className="text-4xl sm:text-5xl md:text-8xl text-white font-bold"
          data-aos="zoom-in-left"
          data-aos-anchor="#example-anchor"
          data-aos-offset="500"
          data-aos-duration="500">
            Fulfillment Policy
          </h1>
          <p className="text-white mt-4 sm:mt-6 font-semibold text-sm sm:text-lg md:text-xl">
            <Link to="/" className="text-yellowcustom hover:underline">
              Home
            </Link>{" "}
            / Fulfillment Policy
          </p>
        </div>
      </div>

      {/* Fulfillment Policy Content */}
      <div className="container mx-auto py-8 px-4 sm:px-8 md:px-12 lg:px-20">
        <div className="text-left">
          <h2 className="text-pink-500 font-bold text-base md:text-lg my-2 md:my-4">
            Overview of Fulfillment Policy
          </h2>
          <p className="text-sm sm:text-base md:text-lg text-gray-700 mb-4">
            Our fulfillment policy outlines the process by which we handle
            orders, shipping, and delivery of goods. We strive to ensure timely
            and accurate fulfillment for all orders placed through our platform.
          </p>

          <h2 className="text-pink-500 font-bold text-base md:text-lg my-2 md:my-4">
            Order Processing
          </h2>
          <p className="text-sm sm:text-base md:text-lg text-gray-700 mb-4">
            Orders are typically processed within 1-2 business days. You will
            receive an email confirmation once your order has been processed and
            is ready for shipment.
          </p>

          <h2 className="text-pink-500 font-bold text-base md:text-lg my-2 md:my-4">
            Shipping and Delivery
          </h2>
          <p className="text-sm sm:text-base md:text-lg text-gray-700 mb-4">
            We offer various shipping options depending on your location and the
            size of your order. Shipping times may vary based on the chosen
            method and destination.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FulfillmentPolicy;
