import React, { useEffect } from "react";
import { FaSmileBeam } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const ThankYou = () => {
  const navigate = useNavigate();

  // Redirect after 5 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/"); // Replace '/' with your desired redirect path
    }, 5000);

    return () => clearTimeout(timer); // Cleanup timer
  }, [navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-pink-400 via-pink-300 to-pink-500">
      <div className="bg-white shadow-2xl rounded-2xl p-10 max-w-lg flex flex-col items-center">
        <div className="text-pink-400 mb-6">
          <FaSmileBeam size={80} className="mx-auto" />
        </div>
        <h1 className="text-4xl font-extrabold text-pink-600 mb-4 text-center">
          Thank You!
        </h1>
        <p className="text-lg text-gray-700 mb-6 text-center">
          Your contact form has been successfully submitted.
        </p>
        <p className="text-md text-gray-600 text-center">
          You will be redirected shortly. If not,{" "}
          <a
            href="/home"
            className="text-pink-600 font-semibold underline hover:text-pink-800"
          >
            click here
          </a>.
        </p>
      </div>
    </div>
  );
};

export default ThankYou;
