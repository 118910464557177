import './App.css';
import Footer from './Components/Footer';
import Navbar from './Components/Navbar';
import Hero from './HomeComponent/Hero';
import Layout from './Layout';
import ContactUs from './Pages/Contactus';

function App() {
  return (
    <div className="App">
     <Layout/>
    </div>
  );
}

export default App;
