import React from "react";
import { FaProjectDiagram, FaUserFriends, FaChartLine, FaDollarSign } from 'react-icons/fa';
import { Link } from "react-router-dom";
import CountUp from 'react-countup';

const AboutWidget = () => {
  return (
    <div className="relative">
      {/* Stats Section */}
      <div className=" bg-gradient-to-r from-purple-400 to-pink-600 ...  text-white py-8">
        <div className="container mx-auto px-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 text-center">
          {/* Successful Projects Delivered */}
          <div className="flex flex-col items-center">
            <h2 className="text-2xl md:text-4xl font-bold mb-2">
              <CountUp end={120} duration={3} />+
            </h2>
            <p className="text-sm md:text-lg font-semibold">Successful Projects Delivered</p>
          </div>

          {/* Client Retention Rate */}
          <div className="flex flex-col items-center">
            <h2 className="text-2xl md:text-4xl font-bold mb-2">
              <CountUp end={95} duration={3} />%
            </h2>
            <p className="text-sm md:text-lg font-semibold">Client Retention Rate</p>
          </div>

          {/* Businesses Gained New Customers */}
          <div className="flex flex-col items-center">
            <h2 className="text-2xl md:text-4xl font-bold mb-2">
              <CountUp end={100} duration={3} />+
            </h2>
            <p className="text-sm md:text-lg font-semibold">Businesses Gained New Customers</p>
          </div>

          {/* Revenue Growth */}
          <div className="flex flex-col items-center">
            <h2 className="text-2xl md:text-4xl font-bold mb-2">
              <CountUp end={150} duration={3} />%
            </h2>
            <p className="text-sm md:text-lg font-semibold">Revenue Growth in the Past Year</p>
          </div>
        </div>
      </div>

      {/* Story Section */}
      <div className="flex flex-col md:flex-row items-center my-16 container mx-auto md:px-8 lg:px-32 px-4" data-aos="slide-up">
        {/* Image Section */}
        <div className="w-full md:w-1/2 rounded-lg overflow-hidden mb-8 md:mb-0" data-aos="slide-up">
          <img
            src="https://ik.imagekit.io/tdlebsr5e/office-2360063_640.jpg?updatedAt=1729676033516"
            alt="Company Overview"
            className="w-full h-auto shadow-xl"
          />
        </div>

        {/* Text Section */}
        <div className="w-full md:w-1/2 md:pl-8 text-left" data-aos="slide-up">
          <h1 className="text-pink-500 font-bold text-lg tracking-wide mb-2">
            OUR STORY
          </h1>
          <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4">
            Choose The Best To Be The BEST
          </h2>
          <p className="text-base sm:text-lg md:text-xl text-gray-700 mb-4 font-semibold">
            Proven Expertise
          </p>
          <p className="text-base sm:text-lg md:text-xl text-gray-700 mb-6">
            With a team of seasoned professionals, we bring a wealth of experience across various industries, ensuring that you receive top-notch service tailored to your specific needs.
          </p>
          <p className="text-base sm:text-lg md:text-xl text-gray-700 mb-6">
            Customized Solutions
          </p>

          {/* Learn More Button */}
          <Link to='/company'>
            <button className="bg-pink-500 mt-4 px-6 py-3 rounded-full text-black hover:bg-pink-800 hover:text-white transition-all duration-300 ease-in-out">
              Learn More
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AboutWidget;
