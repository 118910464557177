import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link, useNavigate } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa";
import emailjs from "emailjs-com";

const ContactUs = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    website: "",
    message: "",
    consent: false,
  });

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
  };

 
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!formData.consent) {
      alert("You must agree to the privacy policy.");
      return;
    }
  
    const templateParams = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      website: formData.website,
      message: formData.message,
      consent : true
    };
  
    try {
      const response = await fetch("https://api.adbrusher.com/api/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(templateParams),
      });
  
      if (response.ok) {
        alert("Message sent successfully!");
        navigate("/thankyou");
        setFormData({
          name: "",
          email: "",
          phone: "",
          website: "",
          message: "",
          consent: false,
        });
      } else {
        alert("Failed to send the message.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("There was an error sending the message.");
    }
  };
  
  return (
    <div>
      {/* Top Section with Background Image and Blue Tint */}
      <div
        className="relative h-1/2 py-32 flex items-center justify-start bg-cover bg-center px-4 sm:px-8 md:px-16 lg:px-32 lg:py-64"
        style={{
          backgroundImage: `url('https://images.pexels.com/photos/1181406/pexels-photo-1181406.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')`,
        }}
        data-aos="slide-in"
      >
        <div className="absolute inset-0 bg-black bg-opacity-80"></div>
        <div className="absolute inset-0 bg-blue-800 bg-opacity-10"></div>

        <div className="relative z-10 text-left">
          <h1
            className="text-4xl sm:text-5xl md:text-7xl text-white font-bold"
            data-aos="zoom-in-left"
            data-aos-offset="500"
            data-aos-duration="500"
          >
            Contact Us
          </h1>
          <p className="text-white mt-6 font-semibold text-lg md:text-2xl">
            <Link to="/" className="text-yellowcustom hover:underline">
              Home
            </Link>{" "}
            / Contact
          </p>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="max-w-full mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 sm:gap-10 lg:gap-16 bg-white">
        {/* Left Section - Contact Info */}
        <div className="space-y-6 p-4 sm:p-8 text-left text-white lg:ml-32">
          <h2 className="text-2xl font-bold text-black" data-aos="slide-up">CONTACT</h2>
          <div data-aos="slide-right">
            <h2 className="text-black text-3xl sm:text-4xl font-bold mb-4">Your Success is Our Mission</h2>
            <p className="text-lg text-black">Let’s Elevate Your Brand Together!</p>
          </div>
          <div data-aos="slide-right">
            <h3 className="text-xl font-semibold text-black">Office Hours</h3>
            <p className="text-black">Monday - Friday: 9 AM - 6 PM</p>
            <p className="text-black">Saturday - Sunday: Closed</p>
          </div>
          <div data-aos="slide-right">
            <h3 className="text-xl font-semibold text-yellowcustom">Phone</h3>
            <p>
              Phone:{" "}
              <a href="tel:+91-9915159299" className="hover:text-pink-600 text-black">
                📞 Call Us: +91-9915159299
              </a>
            </p>
          </div>
          <div data-aos="slide-right">
            <h3 className="text-xl text-black font-semibold text-yellowcustom">Email</h3>
            <p>
              Email:{" "}
              <a href="mailto:adbrusher@gmail.com" className="text-black hover:text-pink-600">
                ✉️Email Us: adbrusher@gmail.com
              </a>
            </p>
          </div>
          <div data-aos="slide-right">
            <h3 className="text-xl font-semibold text-yellowcustom">Address</h3>
            <p className="text-black">🏢 Visit Us: Lower Ground Floor, Plot No.13, Block C-2, Oahfeo Devibe, Ashok Vihar Phase || , North West Delhi, Delhi-110052</p>
          </div>

          {/* <div className="flex space-x-4 mt-4" data-aos="zoom-in">
            {[
              { icon: <FaFacebookF />, color: 'text-blue-600' },
              { icon: <FaInstagram />, color: 'text-pink-500' },
              { icon: <FaLinkedin />, color: 'text-blue-700' },
            ].map(({ icon, color }, index) => (
              <a key={index} href={`https://${color.replace('text-', '')}.com`} className={color}>
                {React.cloneElement(icon, { size: 28 })}
              </a>
            ))}
          </div> */}
          <div className="flex space-x-4 mt-4" data-aos="zoom-in">
           
            {/* <a href="#">
              <i className="fab fa-instagram text-xl sm:text-2xl"></i>
            </a> */}
             <a
              href="https://www.instagram.com/adbrushermedia"
              
              className="text-black bold hover:text-pink-700"
            >
              <i class="fa-brands fa-square-instagram text-2xl"></i>
            </a>


            <a href="https://www.facebook.com/share/Hum41E8CjZzYH9sc/?mibextid=LQQJ4d"
               className="text-black bold hover:text-blue-700">
              <i class="fab fa-facebook-f text-xl sm:text-2xl "></i>
            </a>
            {/* <a href="#">
              <i className="fab fa-twitter text-xl sm:text-2xl"></i>
            </a> */}
            {/* <a href="#">
              <i className="fab fa-linkedin-in text-xl sm:text-2xl text-black hover:text-blue-700"></i>
            </a> */}
            <Link to="https://wa.me/918146880060
" target="_blank"
              
              className="text-black bold hover:text-green-700"
            >
              <i class="fa-brands fa-square-whatsapp text-2xl"></i>
              </Link>


          </div>
        </div>

        {/* Right Section - Contact Form */}
        <div
          className="bg-gradient-to-r from-purple-400 to-pink-600 text-white text-left p-4 sm:p-8 lg:pr-32"
          data-aos="slide-up"
        >
          <h2 className="text-3xl font-bold mb-6">Contact Us</h2>
          <form className="space-y-6 text-left" onSubmit={handleSubmit}>
            {[
              { name: "name", placeholder: "Your Name" },
              { name: "email", placeholder: "Email address" },
              { name: "phone", placeholder: "Phone Number" },
              { name: "website", placeholder: "Website" },
            ].map(({ name, placeholder }, index) => (
              <div key={index}>
                <input
                  type={name === "email" ? "email" : "text"}
                  name={name}
                  className="w-full p-4 border-2 text-black border-gray-300 focus:ring focus:ring-yellowcustom focus:border-yellowcustom placeholder-gray-500 transition duration-300"
                  placeholder={placeholder}
                  onChange={handleChange}
                  required
                />
              </div>
            ))}
            <div>
              <textarea
                rows="4"
                name="message"
                className="w-full p-4 border-2 text-black border-gray-300 focus:ring focus:ring-yellowcustom focus:border-yellowcustom placeholder-gray-500 transition duration-300"
                placeholder="Message"
                onChange={handleChange}
                required
              />
            </div>
            <div className="flex items-start">
              <input
                type="checkbox"
                name="consent"
                className="h-4 w-4 text-yellowcustom text-black border-gray-300 rounded-full focus:ring-yellowcustom"
                onChange={handleChange}
                required
              />
              <label className="ml-2 block text-md text-white font-semibold">
                By checking the box, you consent to the collection and use of
                your phone number and email address for communication purposes
                in accordance with our privacy policy.
              </label>
            </div>
            <button
              type="submit"
              className="bg-pink-500 text-white px-6 py-3 rounded-xl font-bold hover:bg-yellow-600 transition duration-300 w-full sm:w-42"
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
