import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Blog3 = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="bg-gray-100 text-gray-800">
      {/* Banner Section */}
      <div className="relative">
        <img
          className="w-full h-[300px] md:h-[400px] lg:h-[600px] object-cover"
          src="https://ik.imagekit.io/7uve7qsipm/home_06_project_02.jpg?updatedAt=1727439240076"
          alt="Importance of Digital Marketing"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center px-4">
          <h1 className="text-white text-3xl md:text-4xl lg:text-5xl font-bold text-center max-w-4xl leading-tight">
            The Importance of Digital Marketing for Businesses
          </h1>
        </div>
      </div>

      {/* Content Section */}
      <div className="max-w-5xl mx-auto p-4 md:p-6 lg:p-12">
        {/* Introduction */}
        <p
          data-aos="fade-up"
          className="text-base md:text-lg lg:text-xl mb-4 md:mb-6 leading-relaxed"
        >
          In today's digital world, having a strong online presence is essential for businesses to succeed. Thankfully, digital marketing services can boost your online visibility and highlight your brand. By using different strategies and platforms, digital marketing allows you to connect with your target audience in a meaningful and engaging way. From search engine optimization (SEO) to social media advertising, digital marketing includes a wide range of tactics to increase website traffic, improve conversions, and ultimately enhance your profile.
        </p>
        <p
          data-aos="fade-up"
          className="text-base md:text-lg lg:text-xl mb-4 md:mb-6 leading-relaxed"
        >
          It enables you to reach a global audience, target specific demographics, and measure the success of your campaigns with real-time analytics. By using the power of digital marketing services, you can level the business and reach worldwide. Whether you're a small business owner or an established brand, investing in digital marketing is essential for staying relevant in today's competitive marketplace.
        </p>

        {/* Divider */}
        <hr className="my-6 md:my-8 border-gray-300" />

        {/* Section 1: SEO Services */}
        <h2 data-aos="fade-up" className="text-2xl md:text-3xl font-semibold mb-3 md:mb-4">
          Search Engine Optimization (SEO) Services
        </h2>
        <p data-aos="fade-up" className="text-base md:text-lg mb-4 md:mb-6 leading-relaxed">
          Search Engine Optimization (SEO) is a balancing act of different processes to get your site organic rankings through incorporating keywords on the Search Engine Result Page (SERP). It is about creating the analytical framework and devising website architecture, content on the website, the business you do, and the media formats you use.
        </p>
        <p data-aos="fade-up" className="text-base md:text-lg mb-4 md:mb-6 leading-relaxed">
          By delivering the best analytical insights and practices, we help you sail through the tides of your online business. Best practices will be adopted through targeted keywords, content strategies, and the social media you use, helping build reputable businesses.
        </p>

        {/* Section 2: Social Media Marketing Services */}
        <h2 data-aos="fade-up" className="text-2xl md:text-3xl font-semibold mb-3 md:mb-4">
          Social Media Marketing Services
        </h2>
        <p data-aos="fade-up" className="text-base md:text-lg mb-4 md:mb-6 leading-relaxed">
          Using social media in marketing does more than improve site traffic and increase your reach. It turns your business into a personality that your audience can communicate and connect with. A solid social media marketing strategy requires multiple platforms and often a mix of organic and paid methods.
        </p>
        <p data-aos="fade-up" className="text-base md:text-lg mb-4 md:mb-6 leading-relaxed">
          The more you grow your audience using social media, the more profit you will get. With people naturally following and promoting your content, there will be no need for pitching. This approach will help you achieve not just business goals, but also the other unseen benefits that lead to satisfaction and fulfillment.
        </p>

        {/* Section 3: Email Marketing Services */}
        <h2 data-aos="fade-up" className="text-2xl md:text-3xl font-semibold mb-3 md:mb-4">
          Email Marketing Services
        </h2>
        <p data-aos="fade-up" className="text-base md:text-lg mb-4 md:mb-6 leading-relaxed">
          It is much easier to pitch products and services through email than any other form of marketing. Email marketing helps businesses build strong relationships with prospects and customers while moving them through the buyer's journey and maintaining strong communication throughout.
        </p>
        <p data-aos="fade-up" className="text-base md:text-lg mb-4 md:mb-6 leading-relaxed">
          Email marketing requires very little upfront investment, as the only startup cost is the price of the email platform you use. It’s a very personal form of communication, as your message sits directly inside the recipient’s inbox, along with messages from friends and colleagues.
        </p>

        {/* Section 4: Website Design and Development Services */}
        <h2 data-aos="fade-up" className="text-2xl md:text-3xl font-semibold mb-3 md:mb-4">
          Website Design and Development Services
        </h2>
        <p data-aos="fade-up" className="text-base md:text-lg leading-relaxed">
          Having a website that looks good and works well on all devices is crucial. With many people browsing on mobile devices, it’s not enough to focus only on desktops. Responsive web design ensures your site adapts to different screen sizes, whether on a computer, tablet, or smartphone. This creates a better experience for users, encouraging them to stay and explore your content.
        </p>
        <p data-aos="fade-up" className="text-base md:text-lg leading-relaxed">
          Managing a single responsive site is cost-effective, saving you time and money compared to running separate sites for desktop and mobile. Responsive design keeps your site relevant as new devices are released, ensuring it always looks great. In short, investing in responsive web design is essential for reaching and connecting with your audience effectively.
        </p>

        {/* Divider */}
        <hr className="my-6 md:my-8 border-gray-300" />
      </div>
    </div>
  );
};

export default Blog3;
