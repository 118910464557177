import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const DiscountModal = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        email: '',
        phone: '',
    });

    useEffect(() => {
        AOS.init();
        const timer = setTimeout(() => {
            setIsOpen(true);
        }, 8000);

        return () => clearTimeout(timer);
    }, []);

    const toggleModal = () => {
        if (isOpen) {
            setIsClosing(true);
            setTimeout(() => {
                setIsOpen(false);
                setIsClosing(false);
            }, 300);
        } else {
            setIsOpen(true);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await fetch('https://api.adbrusher.com/api/discount', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                alert('Discount code requested!');
                toggleModal(); // Close modal on success
            } else {
                alert('Failed to submit. Please try again.');
            }
        } catch (error) {
            alert('An error occurred. Please try again later.');
            console.error('Error submitting form:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            {/* Message Icon */}
            <div
                className={`fixed left-4 cursor-pointer bg-pink-600 text-white p-5 rounded-full shadow-lg transform transition-all duration-300 ease-in-out ${
                    isOpen ? 'bottom-96' : 'bottom-4'
                }`} 
                onClick={toggleModal}
            >
                <i className="fa-solid fa-envelope fa-2xl"></i>
            </div>

            {/* Modal */}
            {isOpen && (
                <div className={`fixed bottom-0 left-0 right-0 flex items-center justify-center z-[9999] transition-transform duration-500 ease-in-out ${isClosing ? 'transform translate-y-full' : ''}`}>
                    <div className="bg-gradient-to-r from-purple-400 to-pink-600 rounded-t-lg w-full h-auto shadow-lg p-4 sm:p-6 md:p-8" data-aos="slide-up" data-aos-duration="300">
                        <div className="flex justify-between items-center pb-3">
                            <button onClick={toggleModal} className="text-gray-500 hover:text-gray-800 text-3xl">
                                &times;
                            </button>
                        </div>
                        <div className="flex flex-col md:flex-row">
                            {/* Logo Section */}
                            <div className="w-full md:w-1/3 flex items-center justify-center">
                                <img src="https://ik.imagekit.io/6oa6qejxe/Screenshots/Untitled%20design%20(1).png?updatedAt=1727770602380" alt="Logo" className="w-24 h-24 sm:w-32 sm:h-32 md:w-48 md:h-48 object-contain" />
                            </div>

                            {/* Content Section */}
                            <div className="w-full md:w-1/3 p-4 flex flex-col items-start">
                                <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-semibold mb-2 text-center md:text-left">
                                    DON'T LET YOUR WEBSITE HOLD YOU BACK!
                                </h2>
                                <p className="text-gray-700 text-sm sm:text-base md:text-xl text-center md:text-left">
                                    Get 10% Off on your first website with AdBrusher!!
                                </p>
                            </div>

                            {/* Form Section */}
                            <div className="w-full md:w-1/3 p-2 bg-gray-800 bg-opacity-20 rounded-lg shadow-lg">
                                <form onSubmit={handleSubmit}>
                                    <label className="block mb-3">
                                        <input 
                                            type="text" 
                                            name="firstName"
                                            placeholder="First Name" 
                                            className="mt-1 block w-full p-2 border border-gray-600 rounded-full shadow-sm focus:outline-none focus:ring focus:ring-blue-300" 
                                            onChange={handleChange} 
                                            required 
                                        />
                                    </label>
                                    <label className="block mb-3">
                                        <input 
                                            type="email" 
                                            name="email"
                                            placeholder="Email" 
                                            className="mt-1 block w-full p-2 border border-gray-600 rounded-full shadow-sm focus:outline-none focus:ring focus:ring-blue-300" 
                                            onChange={handleChange} 
                                            required 
                                        />
                                    </label>
                                    <label className="block mb-3">
                                        <input 
                                            type="tel" 
                                            name="phone"
                                            placeholder="Phone Number" 
                                            className="mt-1 block w-full p-2 border border-gray-600 rounded-full shadow-sm focus:outline-none focus:ring focus:ring-blue-300" 
                                            onChange={handleChange} 
                                            required 
                                        />
                                    </label>
                                    <button 
                                        type="submit" 
                                        className="w-full bg-black text-white py-2 rounded-full hover:bg-blue-700 transition duration-200"
                                        disabled={loading}
                                    >
                                        {loading ? 'Submitting...' : 'Get Discount Code'}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DiscountModal;
