import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Blog2 = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="bg-gray-100 text-gray-800">
      {/* Banner Section */}
      <div className="relative">
        <img
          className="w-full h-[300px] md:h-[400px] lg:h-[600px] object-cover"
          src="https://ik.imagekit.io/7uve7qsipm/blog_img_01-1024x683.jpg?updatedAt=1727439240009"
          alt="Digital Marketing Insights Banner"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center px-4">
          <h1 className="text-white text-3xl md:text-4xl lg:text-5xl font-bold text-center max-w-4xl leading-tight">
            Your Go-To Hub for Digital Marketing Insights
          </h1>
        </div>
      </div>

      {/* Content Section */}
      <div className="max-w-5xl mx-auto p-4 md:p-6 lg:p-12">
        {/* Introduction */}
        <p
          data-aos="fade-up"
          className="text-base md:text-lg lg:text-xl mb-4 md:mb-6 leading-relaxed"
        >
          Welcome to our digital marketing hub, where we dive deep into the latest trends, share expert tips, and explore innovative strategies in this fast-paced landscape. Whether you’re a business owner eager to elevate your brand, a marketing professional seeking fresh ideas, or just a curious mind wanting to learn more, our articles are crafted to inspire and inform.
        </p>

        {/* Divider */}
        <hr className="my-6 md:my-8 border-gray-300" />

        {/* Section 1: Stay Ahead of the Curve */}
        <h2 data-aos="fade-up" className="text-2xl md:text-3xl font-semibold mb-3 md:mb-4">
          Stay Ahead of the Curve
        </h2>
        <p data-aos="fade-up" className="text-base md:text-lg mb-4 md:mb-6 leading-relaxed">
          In the ever-evolving world of digital marketing, staying informed is key. From emerging technologies to shifting consumer behaviors, understanding these trends can give you a competitive edge. Our team of experts curates the most relevant insights to help you navigate the complexities of digital marketing.
        </p>

        {/* Section 2: Expert Tips for Success */}
        <h2 data-aos="fade-up" className="text-2xl md:text-3xl font-semibold mb-3 md:mb-4">
          Expert Tips for Success
        </h2>
        <p data-aos="fade-up" className="text-base md:text-lg mb-4 md:mb-6 leading-relaxed">
          Looking to improve your online presence? Our blog offers actionable tips and strategies that you can implement immediately. From optimizing your social media campaigns to enhancing your SEO efforts, we provide practical advice that can lead to real results. Learn how to connect with your audience more effectively and drive engagement.
        </p>

        {/* Section 3: Innovative Strategies for Growth */}
        <h2 data-aos="fade-up" className="text-2xl md:text-3xl font-semibold mb-3 md:mb-4">
          Innovative Strategies for Growth
        </h2>
        <p data-aos="fade-up" className="text-base md:text-lg mb-4 md:mb-6 leading-relaxed">
          Innovation is at the heart of successful marketing. We explore cutting-edge strategies that can transform your approach and boost your results. Whether it’s leveraging AI for personalized marketing or utilizing data analytics to inform your decisions, we cover the tactics that are shaping the future of digital marketing.
        </p>

        {/* Section 4: Join the Conversation */}
        <h2 data-aos="fade-up" className="text-2xl md:text-3xl font-semibold mb-3 md:mb-4">
          Join the Conversation
        </h2>
        <p data-aos="fade-up" className="text-base md:text-lg leading-relaxed">
          We believe in the power of community. Share your thoughts, ask questions, and connect with like-minded individuals who are passionate about digital marketing. Together, we can foster a space for learning and growth.
        </p>

        {/* Divider */}
        <hr className="my-6 md:my-8 border-gray-300" />
      </div>
    </div>
  );
};

export default Blog2;
