import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

const OurServices = () => {
    useEffect(() => {
        AOS.init({ duration: 1000, once: true });
    }, []);

    const services = [
        {
            title: 'Mobile App Development',
            description:
                'We create user-friendly mobile applications that enhance customer engagement and streamline business operations. Our apps are tailored to meet your specific needs, ensuring a seamless experience for your users.',
            image: 'https://ik.imagekit.io/zfayt6inj/wwww.png?updatedAt=1727195262706',
            color: 'bg-gradient-to-r from-purple-300 to-pink-500 ...',
            path: '/services/mobile-app-development',
        },
        {
            title: 'Website Development',
            description:
                'Our team builds responsive, visually appealing websites that not only attract visitors but also convert them into customers. We focus on functionality and design, ensuring your site reflects your brand’s identity.',
            image: 'https://ik.imagekit.io/zfayt6inj/wwww.png?updatedAt=1727195262706',
            color: 'bg-gradient-to-r from-purple-300 to-pink-500 ...',
            path: '/services/website-development',
        },
        {
            title: 'SEO Marketing',
            description:
                'We optimize your online presence through targeted SEO strategies that improve your visibility on search engines. Our approach helps you reach your audience effectively, driving organic traffic to your website.',
            image: 'https://ik.imagekit.io/zfayt6inj/seo.png?updatedAt=1727195463565',
            color: 'bg-gradient-to-r from-purple-300 to-pink-500 ...',
            path: '/services/seo-marketing',
        },
        {
            title: 'PPC Ads Campaign',
            description:
                'We manage pay-per-click advertising campaigns that deliver measurable results. Our targeted approach ensures that your ads reach the right people at the right time, maximizing your return on investment.',
            image: 'https://ik.imagekit.io/zfayt6inj/social.png?updatedAt=1727195463707',
            color: 'bg-gradient-to-r from-purple-300 to-pink-500 ...',
            path: '/services/ppc-ads-campaign',
        },
        {
            title: 'Content Branding',
            description:
                'We help you establish a strong brand identity through compelling content. Our content branding services focus on storytelling and messaging that connect with your audience and build trust.',
            image: 'https://ik.imagekit.io/zfayt6inj/social.png?updatedAt=1727195463707',
            color: 'bg-gradient-to-r from-purple-300 to-pink-500 ...',
            path: '/services/content-branding',
        },
        {
            title: 'Email Marketing',
            description:
                'Our email marketing strategies are designed to nurture leads and maintain customer relationships. We create targeted campaigns that keep your audience informed and engaged, driving conversions.',
            image: 'https://ik.imagekit.io/zfayt6inj/social.png?updatedAt=1727195463707',
            color: 'bg-gradient-to-r from-purple-300 to-pink-500 ...',
            path: '/services/email-marketing',
        },
        {
            title: 'Digital Marketing',
            description:
                'Our email marketing strategies are designed to nurture leads and maintain customer relationships. We create targeted campaigns that keep your audience informed and engaged, driving conversions.',
            image: 'https://ik.imagekit.io/zfayt6inj/social.png?updatedAt=1727195463707',
            color: 'bg-gradient-to-r from-purple-300 to-pink-500 ...',
            path: '/services/digital-marketing',
        },
        {
            title: 'Social Media Creative & Strategy',
            description:
                'Our experts craft engaging social media campaigns that resonate with your audience. We develop creative content and strategic plans that increase brand awareness and foster community engagement.',
            image: 'https://ik.imagekit.io/zfayt6inj/social.png?updatedAt=1727195463707',
            color: 'bg-gradient-to-r from-purple-300 to-pink-500 ...',
            path: '/services/social-media-strategy',
        },
    ];

    return (
        <div>
            {/* Top Section with Background Image and Blue Tint */}
            <div
                className="relative h-1/2 py-32 flex items-center justify-start bg-cover bg-center px-4 sm:px-8 md:px-16 lg:px-32 lg:py-64"
                style={{
                    backgroundImage: `url('https://images.pexels.com/photos/1181406/pexels-photo-1181406.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')`,
                }}
                data-aos="slide-in"
            >
                <div className="absolute inset-0 bg-black bg-opacity-80"></div>
                <div className="absolute inset-0 bg-white bg-opacity-10"></div>
                <div className="relative z-10 text-left ">
                    <h1 className="text-4xl sm:text-5xl md:text-7xl text-white font-bold"
                        data-aos="zoom-in-left"
                        data-aos-anchor="#example-anchor"
                        data-aos-offset="500"
                        data-aos-duration="500">
                        Our Services
                    </h1>
                    <p className="text-white mt-6 font-semibold text-lg md:text-2xl">
                        <Link to="/" className="text-yellowcustom hover:underline">
                            Home
                        </Link>{" "} / Our Services
                    </p>
                </div>
            </div>

            {/* Services Section */}
            <div className="bg-white text-black py-16 px-8 sm:px-6 lg:px-16 ">
         <div className="flex flex-col md:px-16">
         <h2 className="text-sm sm:text-md font-semibold text-teal-500 mb-4 text-start">
            S E R V I C E S
          </h2>
                <h2 className="text-4xl md:text-5xl font-extrabold text-start mb-8 md:mb-12" data-aos="slide-down">
                    Our Services
                </h2>
                <p className="text-start mb-8 md:mb-16 text-sm max-w-2xl md:text-base" data-aos="slide-up">
                    We understand that every brand comes with its own unique challenges, and hence requires expertise.
                    At Adbrusher Media, we offer a comprehensive range of services designed to elevate your brand and drive results.
                </p>

         </div>
                <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-8 max-w-7xl mx-auto">
                    {services.map((service, index) => (
                        <div
                            key={index}
                            className={`p-6 sm:p-8 md:p-16 flex flex-col items-start ${service.color} min-h-[380px] sm:min-h-[450px]`}
                            data-aos="slide-up" // Add AOS attribute here
                            data-aos-delay={index * 100}
                        >
                            <div className="flex justify-between items-center w-full mb-4 sm:mb-6 md:mb-10">
                                <h3 className="text-3xl md:text-5xl text-start font-bold">{service.title}</h3>
                                <img
                                    src={service.image}
                                    alt={service.title}
                                    className="w-10 h-10 sm:w-12 sm:h-12 md:w-14 md:h-14 object-cover" // Adjust width and height as needed
                                />
                            </div>

                            <p className="text-left mb-10 mt-4 md:mt-0 text-gray-200 ">{service.description}</p>
                            <Link
                                to={service.path}
                                className="bg-white text-black px-4 py-2 md:py-3 md:px-6 rounded-full font-bold hover:bg-gray-200 transition duration-300"
                            >
                                Get Started
                            </Link>
                        </div>
                    ))}
                </div>
                {/* Let's Work Together Section */}
                <div className="bg-cover text-white py-16 mt-16 flex flex-col lg:flex-row items-center justify-between max-w-7xl mx-auto" data-aos="slide-up">
                    <div className="w-full lg:w-1/2 mb-8 lg:mb-0">
                        <img src="https://ik.imagekit.io/zfayt6inj/work.jpg?updatedAt=1727240144020" alt="Let's Work Together" className="shadow-lg" />
                    </div>
                    <div className="w-full lg:w-1/2 lg:pl-10 text-left">
                        <h3 className="text-black text-sm font-semibold mb-6">C O N T A C T</h3>
                        <h2 className=" text-black text-3xl md:text-5xl font-bold mb-4">Let’s Work Together!</h2>
                        <p className="text-black mb-8">
                            Join forces with us to unlock the extraordinary. With our innovative strategies and out-of-the-box thinking,
                            together we’ll create a one-of-a-kind marketing experience that sets your brand apart.
                            Get in touch today and let’s embark on a journey of unrivaled success.
                        </p>
                        <Link
                            to="/contact"
                            className="bg-pink-600 text-white px-7 py-3 rounded-full font-bold hover:bg-red-500 hover:scale-105 transform transition duration-300 ease-in-out"
                        >
                            Contact Us Now
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurServices;