import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const Footer = () => {
  useEffect(() => {
    AOS.init({ duration: 1600 });
  }, []);

  return (
    <footer className="w-full bg-white text-black py-12 px-4 sm:px-8 md:px-16 lg:px-32" style={{ zIndex: -1 }}>
      <div className="container mx-auto flex flex-col lg:flex-row lg:justify-between justify-center align-center">
        {/* Left Section */}
        <div
  data-aos="slide-up"
  className="mb-12 lg:mb-0 lg:w-1/3 text-center flex flex-col items-center justify-center"
>
  <img
    src="https://ik.imagekit.io/6oa6qejxe/Screenshots/Untitled%20design%20(1).png?updatedAt=1727770602380"
    alt="Logo"
    className="h-1/2 w-1/2 mb-6"
  />
  <p className="text-sm sm:text-md leading-relaxed mb-6 text-center">
    At Adbrusher Media, we’re here to help you elevate your brand and achieve your digital marketing goals. With a team of experienced digital marketing professionals, we specialize in crafting tailored strategies that drive results.
  </p>
</div>


        {/* Contact Us Section */}
        <div
          data-aos="slide-up"
          className="mb-12 lg:mb-0 lg:w-1/4 text-left flex flex-col"
        >
          <h3 className="text-sm sm:text-md font-bold mb-4 sm:mb-6">Contact Us</h3>
          <p className="text-sm sm:text-md mb-4">
            Head Quarters:
            <br />
            Lower Ground Floor, Plot No.13, Block C-2, Oahfeo Devibe, Ashok Vihar Phase || , North West Delhi, Delhi-110052
          </p>
          <p>
            Email:{" "}
            <a href="mailto:adbrusher@gmail.com" className="hover:text-pink-600">
            adbrusher@gmail.com
            </a>
          </p>
          {/* <p className="text-sm sm:text-md mb-2">Email: adbrusher@gmail.com</p> */}
          {/* <p className="text-sm sm:text-md">IND: +91-9915159299</p> */}
          <p>
            Phone:{" "}
            <a href="tel:+91-9915159299" className="hover:text-pink-600">
            +91-9915159299
            </a>
          </p>
          <div className="flex space-x-4 mt-4">
           
            {/* <a href="#">
              <i className="fab fa-instagram text-xl sm:text-2xl"></i>
            </a> */}
             <a
              href="https://www.instagram.com/adbrushermedia"
              
              className="text-black bold hover:text-pink-700"
            >
              <i class="fa-brands fa-square-instagram text-2xl "></i>
            </a>

            <a href="https://www.facebook.com/share/Hum41E8CjZzYH9sc/?mibextid=LQQJ4d">
              <i className="fab fa-facebook-f text-xl sm:text-2xl hover:text-blue-700"></i>
            </a>
            {/* <a href="#">
              <i className="fab fa-twitter text-xl sm:text-2xl"></i>
            </a> */}
            <Link to="https://wa.me/918146880060
" target="_blank"
              
              className="text-black bold hover:text-green-700"
            >
              <i class="fa-brands fa-square-whatsapp text-2xl"></i>
              </Link>
          </div>
        </div>

        {/* Our Location Section */}
        <div
          data-aos="slide-up"
          className="lg:w-1/4 text-left flex flex-col"
        >
          <h3 className="text-sm sm:text-md font-bold mb-4 sm:mb-6">Our Location</h3>
          <div className="relative h-48 w-full">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3499.8087164056747!2d77.16907557529147!3d28.695368075630462!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d0387ed92fd7b%3A0x65f471f38421387b!2sOahfeo%20De-Vibe!5e0!3m2!1sen!2sin!4v1727942356448!5m2!1sen!2sin"
              title="Google Map"
              className="w-full h-full border-0"
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>

      <div className="mt-8 flex flex-col items-center lg:flex-row lg:items-center lg:justify-center lg:space-x-8 text-sm sm:text-md">
        <div className="flex space-x-6 lg:space-x-12">
          <Link
            to="/TermsAndConditions"
            className="hover:text-gray-400 transition-all"
          >
            Terms and Conditions
          </Link>
          <Link
            to="/services/CookiePolicy"
            className="hover:text-gray-400 transition-all"
          >
            Cookie Policy
          </Link>
          <Link
            to="/privacyPolicy"
            className="hover:text-gray-400 transition-all"
          >
            Privacy Policy
          </Link>
          <Link
            to="/services/fulfillmentPolicy"
            className="hover:text-gray-400 transition-all"
          >
            Fulfillment Policy
          </Link>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="border-t border-gray-500 mt-8 pt-4 lg:mt-12 lg:pt-8">
        <div className="container mx-auto flex flex-col lg:flex-row justify-between items-center">
          <div className="mb-4 lg:mb-0 text-center lg:text-left text-sm sm:text-md">
            © 2024 — Adbrusher All Rights Reserved.
          </div>
          {/* <div className="flex space-x-4 sm:space-x-6">
            <i className="fab fa-cc-visa text-black text-xl sm:text-2xl"></i>
            <i className="fab fa-cc-mastercard text-black text-xl sm:text-2xl"></i>
            <i className="fab fa-cc-paypal text-black text-xl sm:text-2xl"></i>
            <i className="fab fa-cc-stripe text-black text-xl sm:text-2xl"></i>
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
