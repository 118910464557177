import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import { Link } from "react-router-dom";

const SocialMedia = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  return (
    <div>
      {/* Top Section with Background Image and Blue Tint */}
      <div
        className="relative h-screen flex items-center justify-start bg-cover bg-center"
        style={{
          backgroundImage: `url('https://ik.imagekit.io/tdlebsr5e/2150063164.jpg?updatedAt=1731398763535')`,
        }}
        data-aos="slide-in"
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative z-10 text-left p-8">
          <h1
            className="text-5xl md:text-7xl text-white font-bold mt-32"
            data-aos="zoom-in-left"
          >
            Social Media Marketing
          </h1>
          <p className="text-sky-400 my-6 font-semibold text-lg tracking-wider">
          Premier Social Media Marketing Services
          </p>
        </div>
      </div>

      {/* Main Content Section */}
      <div className="bg-white text-black py-20 px-4">
        <h1 className="text-3xl font-bold mb-4 text-gray-800">
          Connect, Engage, and Flourish!
        </h1>
        <section className="py-12 px-4 bg-gray-50 mb-12 flex flex-col md:flex-row justify-center mx-auto max-w-7xl">
          <div className="md:w-1/2 flex flex-col justify-center items-center text-center md:text-left">
            <p className="text-base mb-4 max-w-4xl mx-auto text-gray-700">
              In an era where your audience is just a click away, investing in
              social media management is essential. Social media platforms like
              Facebook, X (formerly Twitter), and Instagram are woven into the
              fabric of daily life for billions of people. With over 2 billion
              active users on Facebook alone representing more than a quarter of
              the world's population it’s clear that these channels offer
              unparalleled reach.
            </p>
            <p className="text-base mb-4 max-w-4xl mx-auto text-gray-700">
              Given this vast audience, it’s no surprise that social media has
              become an invaluable asset for businesses. If you’re not sharing
              updates on Facebook, engaging with trends on X, or showcasing
              visuals on Instagram, you could be missing crucial opportunities
              for visibility and growth.
            </p>
            <p className="text-base mb-4 max-w-4xl mx-auto text-gray-700">
              Successful social media marketing involves more than just posting
              content. That’s where we come in. Let us guide you through the
              complexities of social media to enhance your brand’s presence and
              effectiveness.
            </p>

            <ul className="list-disc list-inside max-w-4xl mx-auto text-base text-left space-y-4 text-gray-700">
              <li>
                <strong>Expand Your Reach:</strong> With billions of users on
                social platforms, our social media marketing services help you
                tap into a vast audience, ensuring your brand doesn’t go
                unnoticed.
              </li>
              <li>
                <strong>Build Authentic Relationships:</strong> Engage directly
                with your customers, foster community, and transform casual
                followers into loyal advocates for your brand.
              </li>
              <li>
                <strong>Gain Valuable Insights:</strong> Leverage real-time
                feedback from your audience to stay ahead of trends and tailor
                your content to meet their preferences.
              </li>
              <li>
                <strong>Cost-Effective Strategies:</strong> Our targeted
                advertising campaigns ensure your marketing budget is used
                efficiently, driving impressive returns on investment.
              </li>
            </ul>
          </div>

          <div className="md:w-1/2 flex flex-col gap-8 justify-center items-center mt-8 md:mt-0">
            <img
              src="https://images.pexels.com/photos/3184301/pexels-photo-3184301.jpeg"
              alt="Social Media Strategy"
              className="w-full md:w-2/3 h-60 object-cover rounded-lg shadow-md"
            />
            <img
              src="https://images.pexels.com/photos/3184299/pexels-photo-3184299.jpeg"
              alt="Social Media Strategy"
              className="w-full md:w-2/3 h-60 object-cover rounded-lg shadow-md"
            />
          </div>
        </section>

        <section className="py-12 px-4 bg-gray-50 text-center  bg-gradient-to-r from-purple-300 to-pink-200 ">
          <h2 className="text-4xl font-bold mb-6 text-gray-800">
            Our Social Media Management Solutions for Your Brand’s Growth!
          </h2>
          <p className="text-lg mb-8 text-gray-700 max-w-3xl mx-auto">
            At Adbrusher Media, we offer a wide range of social media marketing
            services designed to elevate your brand. Here’s how we can help you
            succeed:
          </p>
         <div className="flex flex-wrap justify-center space-x-2  max-w-8xl mx-auto">
  <div className="bg-white p-4 rounded-lg flex-1 min-w-[200px] text-left  shadow hover:bg-pink-400 transition duration-200">
    <h4 className="font-bold mb-2">Strategic Planning:</h4>
    <p>
      We create a tailored social media strategy that aligns with your business objectives, ensuring you reach the right audience at the right time.
    </p>
  </div>
  <div className="bg-white p-4 rounded-lg flex-1 min-w-[250px] text-left  shadow hover:bg-pink-400 transition duration-200">
    <h4 className="font-bold mb-2">Content Creation:</h4>
    <p>
      Our team produces high-quality content from stunning graphics to engaging videos designed to captivate your followers and encourage interaction.
    </p>
  </div>
  <div className="bg-white p-4 rounded-lg flex-1 min-w-[250px] text-left  shadow hover:bg-pink-400 transition duration-200">
    <h4 className="font-bold mb-2">Community Management:</h4>
    <p>
      We actively manage your social media accounts, engaging with your audience, responding to comments, and fostering a vibrant online community.
    </p>
  </div>
  <div className="bg-white p-4 rounded-lg flex-1 min-w-[250px] text-left  shadow hover:bg-pink-400 transition duration-200">
    <h4 className="font-bold mb-2">Targeted Advertising:</h4>
    <p>
      Our data-driven approach to paid advertising ensures your campaigns are optimized for maximum reach and engagement, driving valuable traffic to your site.
    </p>
  </div>
  <div className="bg-white p-4 rounded-lg flex-1 min-w-[250px] text-left  shadow hover:bg-pink-400 transition duration-200">
    <h4 className="font-bold mb-2">In-Depth Analytics:</h4>
    <p>
      We track and analyze key performance metrics, providing you with comprehensive reports that highlight your campaign's effectiveness and areas for improvement.
    </p>
  </div>
</div>

        </section>

        {/* Importance of SEO */}
        <section className="py-12 px-4 bg-gray-50 text-center mb-12">
          <h2 className="text-4xl font-semibold mb-8 text-gray-800">
            We've Mastered Major Social Media Networks
          </h2>
          <p className="text-lg mb-10 text-gray-700">
            When you partner with a social media marketing agency that
            understands these platforms inside and out, you’ll witness a
            remarkable transformation in your online presence (and trust us it
            will be impressive!).
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 max-w-6xl mx-auto">
  <div className="p-6 bg-white border-l-4 border-blue-500 transition-transform duration-300 hover:transform hover:-translate-y-1 shadow-lg rounded-lg">
    <strong className="text-gray-800 text-center">Facebook:</strong> With 2.8 billion monthly users, Facebook remains the dominant social media platform. It offers an incredible opportunity to connect with your audience through engaging content, targeted boosted posts, and smart Facebook ads that drive results.
  </div>

  <div className="p-6 bg-white border-l-4 border-green-500 transition-transform duration-300 hover:transform hover:-translate-y-1 shadow-lg rounded-lg">
    <strong className="text-gray-800 text-center">Instagram:</strong> Foster a loyal community of followers and brand advocates through stunning photos, videos, and Reels. On Instagram, 83% of users report that the platform influences their purchasing decisions, making it a powerful tool for businesses looking to engage and convert.
  </div>

  <div className="p-6 bg-white border-l-4 border-blue-400 transition-transform duration-300 hover:transform hover:-translate-y-1 shadow-lg rounded-lg">
    <strong className="text-gray-800 text-center">X (Twitter):</strong> X's fast-paced environment allows your message to reach a wide audience quickly. It’s an ideal platform for providing customer support, engaging with your followers, and showcasing your brand's personality in real time.
  </div>

  <div className="p-6 bg-white border-l-4 border-purple-500 transition-transform duration-300 hover:transform hover:-translate-y-1 shadow-lg rounded-lg">
    <strong className="text-gray-800 text-center">LinkedIn:</strong> Build valuable connections with business leaders and decision-makers on LinkedIn, the leading B2B network. With strategic content and targeted advertising, you can enhance your brand’s visibility and establish authority within your industry.
  </div>

  <div className="p-6 bg-white border-l-4 border-red-500 transition-transform duration-300 hover:transform hover:-translate-y-1 shadow-lg rounded-lg">
    <strong className="text-gray-800 text-center">YouTube:</strong> As the second-largest search engine globally (after Google), YouTube offers a vast platform for businesses to share content, enhance their online presence, and promote products and services effectively.
  </div>

  <div className="p-6 bg-white border-l-4 border-pink-500 transition-transform duration-300 hover:transform hover:-translate-y-1 shadow-lg rounded-lg">
    <strong className="text-gray-800 text-center">Pinterest:</strong> Pinterest is all about discovery, making it an ideal platform for brands looking to expand their audience. By utilizing eye-catching pins and compelling ads, you can connect with users who are genuinely interested in your offerings.
  </div>

  <div className="p-6 bg-white border-l-4 border-orange-500 transition-transform duration-300 hover:transform hover:-translate-y-1 shadow-lg rounded-lg">
    <strong className="text-gray-800 text-center">Snapchat:</strong> Engage your customers in a fresh and creative way with Snapchat’s Geofilters and ads. Our social media team will help you design impactful campaigns that resonate with your audience and deliver measurable results.
  </div>

  <div className="p-6 bg-white border-l-4 border-yellow-500 transition-transform duration-300 hover:transform hover:-translate-y-1 shadow-lg rounded-lg">
    <strong className="text-gray-800 text-center">Google Business Profile (GBP):</strong> Every business recognizes the importance of Google Business Profile (formerly Google My Business) in enhancing visibility and reputation. Optimize your listing with compelling posts to ensure  results and attract more customers.
  </div>


          </div>
        </section>

        {/* Social Media Management Solutions */}
        <section className="text-center mb-12">
          <h2 className="text-3xl font-semibold mb-8">
            Our Social Media Management Solutions
          </h2>
          <p className="mb-4">
            Explore our services that can propel your brand’s growth:
          </p>
          <div className="flex flex-wrap justify-center gap-6">
            <div className="max-w-md bg-white p-5 text-gray-900 rounded-lg shadow-md">
              <h3 className="text-xl font-bold mb-2">Strategic Planning</h3>
              <p>
                Tailored social media strategies that align with your business
                objectives.
              </p>
            </div>
            <div className="max-w-md bg-white p-5 text-gray-900 rounded-lg shadow-md">
              <h3 className="text-xl font-bold mb-2">Community Management</h3>
              <p>
                Engage with your audience and foster a vibrant online community.
              </p>
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="bg-gradient-to-r from-purple-200 to-pink-400 text-center py-8 mb-12">
          <h2 className="text-4xl font-semibold mb-4">
            Your success story is just a click away!
          </h2>
          <Link to="/contact">
            <button className="bg-pink-500 hover:bg-blue-700 text-white font-semibold py-2 px-6 rounded-lg">
              Let’s Get Started
            </button>
          </Link>
        </section>

        {/* Additional Images Section */}
      </div>
    </div>
  );
};

export default SocialMedia;
