import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import { Link } from "react-router-dom";

const SEOMarketingPage = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  return (
    <div className="bg-gray-100">
      {/* Top Section with Background Image and Blue Tint */}
      <div
        className="relative h-1/2 py-32 flex items-center justify-start bg-cover bg-center px-4 sm:px-8 md:px-16 lg:px-32 lg:py-64"
        style={{
          backgroundImage: `url('https://ik.imagekit.io/tdlebsr5e/2150063136.jpg?updatedAt=1729148709453')`,
        }}
        data-aos="slide-in"
      >
        <div className="absolute inset-0 bg-black bg-opacity-60"></div>
        <div className="absolute inset-0 bg-blue-800 bg-opacity-30"></div>
        <div className="relative z-10 text-center">
          <h1
            className="text-4xl sm:text-5xl md:text-7xl text-white font-bold"
            data-aos="zoom-in-center"
          >
            Search Engine Optimization
          </h1>
          <p className="text-sky-400 my-6 font-semibold text-left text-md md:text-md tracking-wider">
            SEO SERVICES TAILORED FOR YOUR SUCCESS
          </p>
        </div>
      </div>

      {/* Content Section */}
      <div className="bg-white text-black py-20 px-4 text-center">
        <h1 className="text-4xl font-bold mb-6">
          Top-Ranked SEO Services to Boost Your Business Potential
        </h1>
        <p className="text-md">
          Partner with Adbrusher Media for exceptional SEO services that deliver
          measurable outcomes.
        </p>
       
      </div>
      <section>
        <div class="bg-gray-100 py-12 px-6 md:px-20  bg-white">
          <div class="max-w-7xl mx-auto text-center ">
            <h2 class="text-3xl md:text-5xl font-bold text-gray-800 mb-12">
              Importance of Investing in SEO Today
            </h2>
            <div className="flex justify-center mb-10 py-12">
            <img
        src="https://ik.imagekit.io/tdlebsr5e/2150063136.jpg?updatedAt=1729148709453"
        alt="Digital marketing illustration"
        className="h-70 w-full md:w-auto rounded-lg transition-transform transform hover:scale-105"
      />
             </div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div class="bg-purple-200 shadow-lg rounded-lg p-6 md:p-8 ">
                {/* <h3 class="text-xl md:text-2xl font-semibold text-purple-600 mb-4">
                  The Power of SEO in a Crowded Market
                </h3> */}
                <p class="text-gray-700 text-lg leading-relaxed text-j">
                  Every day, Google sees 3.5 billion searches, making visibility
                  essential in a crowded marketplace. To stand out in search
                  results, your business needs expert keyword research and
                  refined SEO strategies that truly enhance your online presence
                  and contribute to your bottom line.
                </p>
              </div>

              <div class="bg-purple-200 shadow-lg rounded-lg p-6 md:p-8">
                <h3 class="text-xl md:text-2xl font-semibold text-pink-600 mb-4"></h3>
                <p class="text-gray-700 text-lg leading-relaxed text-center">
                  When you partner with an SEO provider, you gain access to a
                  dedicated team of professionals focused on your digital
                  success. SEO consulting services help your website appear on
                  the first page of search results, making it more likely that
                  potential customers will find you.
                </p>
              </div>
              <div class="bg-purple-200 shadow-lg rounded-lg p-6 md:p-8 ">
                {/* <h3 class="text-xl md:text-2xl font-semibold text-purple-600 mb-4">
                  The Power of SEO in a Crowded Market
                </h3> */}
                <p class="text-gray-700 text-center text-lg leading-relaxed ">
                  Unlike paid advertising, organic search results are free.
                  Although SEO requires an investment of time and resources, the
                  long-term benefits can far outweigh these initial costs,
                  resulting in a sustainable traffic source.
                </p>
              </div>
              <div class="bg-purple-200 shadow-lg rounded-lg p-6 md:p-8 ">
                {/* <h3 class="text-xl md:text-2xl font-semibold text-purple-600 mb-4">
                  The Power of SEO in a Crowded Market
                </h3> */}
                <p class="text-gray-700  text-center text-lg leading-relaxed ">
                  Good SEO practices focus on optimizing your website’s
                  structure and content, leading to a better user experience.
                  This, in turn, can decrease bounce rates and improve
                  engagement.
                </p>
              </div>
              <div class="flex justify-center bg-purple-200  shadow-lg rounded-lg p-6 md:p-8 ">
                {/* <h3 class="text-xl md:text-2xl font-semibold text-purple-600 mb-4">
                  The Power of SEO in a Crowded Market
                </h3> */}
                <p class="text-gray-700 text-center  text-lg leading-relaxed text-center">
                  High-ranking sites are often perceived as more trustworthy by
                  users. Effective SEO strategies help establish your brand as
                  an authority in your field.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 bg-gradient-to-r from-gray-100 to-gray-200 text-gray-900">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-4xl font-bold mb-6 text-center text-gray-800">
            Our Range of SEO Services at Adbrusher Media
          </h2>

          <p className="text-md mb-8 max-w-3xl text-center mx-auto text-gray-700">
            At Adbrusher Media, we offer a comprehensive suite of Search engine
            optimization services to meet your needs:
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                title: "Keyword Research",
                icon: "🔍",
                description:
                  "Our team conducts thorough keyword research to identify the terms and phrases that your target audience is searching for. This ensures that your content is optimized for the most relevant keywords, increasing your chances of ranking higher.",
              },
              {
                title: "On-Page Optimization",
                icon: "📄",
                description:
                  "We optimize key on-page elements such as title tags, meta descriptions, headers, and content. Our focus is on ensuring that each page is relevant and valuable to both users and search engines.",
              },
              {
                title: "Technical SEO",
                icon: "⚙️",
                description:
                  "We perform technical audits to identify issues affecting your site’s performance, optimizing site speed, improving mobile usability, and enhancing site architecture for better crawlability.",
              },
              {
                title: "Content Creation",
                icon: "✍️",
                description:
                  "Quality content is essential for effective SEO. Our team develops engaging, informative, and keyword-rich content that attracts and retains visitors, boosting conversions.",
              },
              {
                title: "Link Building",
                icon: "🔗",
                description:
                  "We employ ethical link-building strategies to enhance your site's authority, acquiring high-quality backlinks from reputable sources to improve search rankings.",
              },
              // { title: "Local SEO", icon: "📍", description: "For businesses serving specific areas, our local SEO services connect you with local customers through optimized online presence and managing your Google My Business profile." },
              {
                title: "Continuous Monitoring and Reporting",
                icon: "📊",
                description:
                  "SEO is an ongoing process. We continually monitor your website’s performance, providing detailed reports and adjusting strategies as needed to ensure optimal results.",
              },
            ].map((service, index) => (
              <div
                key={index}
                className="bg-white rounded-lg p-6 shadow-lg hover:shadow-xl transition-shadow duration-200 transform hover:scale-105"
              >
                <div className="text-4xl mb-4 text-center text-mainpink">
                  {service.icon}
                </div>
                <h3 className="text-xl font-semibold mb-4 text-gray-800 text-center">
                  {service.title}
                </h3>
                <p className="text-md text-justify text-gray-600">
                  {service.description}
                </p>
              </div>
            ))}
          </div>

          {/* <div className="flex justify-center mt-12">
      <img
        src="https://ik.imagekit.io/tdlebsr5e/2150063136.jpg?updatedAt=1729148709453"
        alt="Digital marketing illustration"
        className="max-h-96 w-full md:w-auto rounded-lg transition-transform transform hover:scale-105"
      />
    </div> */}
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="py-8 px-8 lg:px-16 bg-gradient-to-r from-purple-300 to-pink-200 text-center">
        <h2 className="text-4xl font-bold mb-6 text-gray-800">
          Let’s Transform Your Online Presence Together
        </h2>
        <h3 className="text-xl font-semibold mb-10 text-gray-700">
          Contact us today to learn more about our SEO services and discover how
          we can help your business thrive online!
        </h3>
        <Link to="/contact">
          <button className="bg-pink-600 hover:bg-blue-700 text-white font-bold py-3 px-10 rounded-lg shadow-lg transition duration-300 transform hover:scale-105">
            Connect with Us
          </button>
        </Link>
      </section>

      <section className="pt-16 bg-gray-50 text-gray-900 flex justify-center">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-4xl font-bold mb-12 text-gray-800 text-center">
            Delivering Results That You Can Measure
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mx-auto max-w-4xl">
            <div className=" bg-white text-left space-y-4 p-6 border border-purple-400 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
              <h3 className="text-3xl font-semibold text-gray-800">
                Increased Organic Traffic
              </h3>
              {/* <hr className="border-t border-purple-300" /> */}
              <p className="text-lg leading-relaxed text-gray-700 text-justify">
                Our targeted search engine optimization strategies lead to a
                significant rise in organic traffic, bringing more potential
                customers to your website.
              </p>
            </div>
            <div className=" bg-white text-left space-y-4 p-6 border border-purple-400 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
              <h3 className="text-3xl font-semibold text-gray-800">
                Higher Search Rankings
              </h3>
              {/* <hr className="border-t border-purple-300" /> */}
              <p className="text-lg leading-relaxed text-gray-700 text-justify">
                Many of our clients see their websites ranking on the first page
                of search results within months of implementing our strategies.
              </p>
            </div>
            <div className=" bg-white text-left space-y-4 p-6 border border-purple-400 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
              <h3 className="text-3xl font-semibold text-gray-800">
                Improved Engagement
              </h3>
              {/* <hr className="border-t border-purple-300" /> */}
              <p className="text-lg leading-relaxed text-gray-700 text-justify">
                Enhanced user experience and quality content result in lower
                bounce rates and longer time spent on site, leading to higher
                engagement levels.
              </p>
            </div>
            <div className=" bg-white text-left space-y-4 p-6 border border-purple-400 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
              <h3 className="text-3xl font-semibold text-gray-800">
                Boosted Conversion Rates
              </h3>
              {/* <hr className="border-t border-purple-300" /> */}
              <p className="text-lg leading-relaxed text-gray-700 text-justify">
                By attracting the right audience and providing valuable content,
                our clients often experience a substantial increase in
                conversion rates.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SEOMarketingPage;
