import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import { Link } from "react-router-dom";

const EmailMarketing = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  return (
    <div>
      {/* Top Section with Background Image and Blue Tint */}
      <div
        className="relative h-1/2 py-32 flex items-center justify-start bg-cover bg-center px-4 sm:px-8 md:px-16 lg:px-32 lg:py-64"
        style={{
          backgroundImage: `url('https://ik.imagekit.io/tdlebsr5e/195%20(1).jpg?updatedAt=1731395524748')`,
        }}
        data-aos="slide-in"
      >
        <div className="absolute inset-0 bg-black bg-opacity-80"></div>
        <div className="absolute inset-0 bg-blue-800 bg-opacity-10"></div>
        <div className="relative z-10 text-left">
          <h1
            className="text-4xl sm:text-5xl md:text-7xl text-white font-bold"
            data-aos="zoom-in-left"
            data-aos-anchor="#example-anchor"
            data-aos-offset="500"
            data-aos-duration="500"
          >
            Email Marketing
          </h1>
          <p className="text-sky-400 my-6 font-semibold text-lg md:text-md tracking-wider">
           
Reach Your Target Audience Directly With Email Marketing Company
          </p>
        </div>
      </div>

      <div className="bg-white text-black">
        {/* Fold 1 */}
        <section className="py-20 px-4 text-center" data-aos="slide-up">
          <h1 className="text-4xl font-bold mb-6">
            Reach Your Target Audience Directly With Email Marketing Company
          </h1>
          <p className="text-lg mb-8">
            At Adbrusher Media, we specialise in comprehensive email marketing
            services that help you connect with customers, foster loyalty, and
            make a real impact on your business.
          </p>
          <div className="flex justify-center mt-10">
            {/* <img
              src="https://ik.imagekit.io/tdlebsr5e/13786.jpg?updatedAt=1729153582682"
              alt="Email Marketing"
              className="w-full max-w-xl rounded-lg shadow-lg"
            /> */}
          </div>
        </section>

        {/* Fold 2 - Importance of Website Design */}
        <section className="py-16 px-4 bg-white text-black" data-aos="slide-up">
      <h2 className="text-4xl text-center font-semibold mb-8 text-gray-800">
        Achieve Remarkable ROI: Email’s Proven Value!
      </h2>
      <div className="max-w-7xl mx-auto text-lg leading-relaxed">
        <p className="mb-6 text-center">
          Email marketing provides a direct line to your customers, delivering your message straight to their inboxes. Unlike social media platforms, where visibility can be unpredictable, email ensures your content reaches its intended audience, fostering trust and brand loyalty.
        </p>

        <p className="mb-6 text-center">
          Email marketing consistently offers one of the highest returns on investment (ROI) among digital marketing channels. Studies show that for every dollar spent, businesses can expect an average return of $42. This makes it a cost-effective choice for driving sales and nurturing customer relationships.
        </p>
        
        <p className="mb-6 text-center">
          With our email campaign management, you can create highly targeted email campaigns that cater to specific segments of your audience. Personalization enhances engagement, ensuring your content is relevant and resonates with each recipient.
        </p>
      </div>
    </section>

        {/* Fold 3 - Services */}
        <section className="py-16 px-4" data-aos="slide-up">
          <h2 className="text-4xl font-semibold text-center mb-8">
            Our Dynamic Email Marketing Services That Nurture and Convert Leads
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 justify-items-center">
  <div className="max-w-xl bg-gradient-to-r from-purple-200 to-pink-400 md:p-10 p-5 text-gray-900">
    <h3 className="md:text-2xl text-xl font-bold">
      Email Campaign Management
    </h3>
    <p>
      Our expert team manages every aspect of your email campaigns, from
      strategy development to execution. We continuously monitor performance
      metrics and make real-time adjustments to maximise engagement and
      conversion rates.
    </p>
  </div>
  <div className="max-w-xl bg-gradient-to-r from-purple-200 to-pink-400 md:p-10 p-5 text-gray-900">
    <h3 className="md:text-2xl text-xl font-bold">
      List Building and Management
    </h3>
    <p>
      A robust email list is crucial for successful email marketing. We assist
      in building and maintaining a quality subscriber list, employing best
      practices to grow your audience while ensuring compliance with
      regulations.
    </p>
  </div>
  <div className="max-w-xl bg-gradient-to-r from-purple-200 to-pink-400 md:p-10 p-5 text-gray-900">
    <h3 className="md:text-2xl text-xl font-bold">
      Automated Email Workflows
    </h3>
    <p>
      Our team implements automated workflows to nurture leads and engage
      customers based on their interactions with your brand. This ensures
      timely follow-ups and enhances the overall customer experience.
    </p>
  </div>
  <div className="max-w-xl bg-gradient-to-r from-purple-200 to-pink-400 md:p-10 p-5 text-gray-900">
    <h3 className="md:text-2xl text-xl font-bold">
      Creative Content Development
    </h3>
    <p>
      Our talented copywriters and designers create visually appealing and
      compelling email content that grabs attention.
    </p>
  </div>
  <div className="max-w-xl bg-gradient-to-r from-purple-200 to-pink-400 md:p-10 p-5 text-gray-900">
    <h3 className="md:text-2xl text-xl font-bold">
      Performance Analytics
    </h3>
    <p>
      We provide detailed analytics and reporting to track the success of your
      email campaigns.
    </p>
  </div>
  <div className="max-w-xl bg-gradient-to-r from-purple-200 to-pink-400 md:p-10 p-5 text-gray-900">
    <h3 className="md:text-2xl text-xl font-bold">
      Targeted Email Campaigns
    </h3>
    <p>
      We specialise in crafting effective targeted email campaigns that reach
      the right people with the right message.
    </p>
  </div>
</div>

          <div className="flex justify-center mt-10">
            {/* <img
              src="https://ik.imagekit.io/tdlebsr5e/195.jpg?updatedAt=1729152682972"
              alt="Services Overview"
              className="w-full max-w-xl rounded-lg shadow-lg"
            /> */}
          </div>
        </section>

        {/* CTA Fold */}
        <section
          className="py-8 px-4 bg-gradient-to-r from-purple-200 to-pink-400 text-center"
          data-aos="slide-up"
        >
          <h2 className="text-4xl font-semibold mb-4">
            Your Trusted Ally in Effective Email Marketing Solutions!
          </h2>
          <Link to="/contact">
            <h2 className="text-2xl font-semibold mb-8">
              Let’s collaborate to create impactful email campaigns that propel
              your business forward and drive conversions!
            </h2>
            <button className=" bg-pink-500 hover:bg-blue-700 text-white font-semibold py-2 px-6 rounded-lg">
              Connect Now
            </button>
          </Link>
        </section>

        {/* Fold 5 - Why We're Your Ideal Partner */}
        <section className="py-16 px-4 bg-white text-black" data-aos="slide-up">
          <h2 className="text-4xl text-center font-semibold mb-8 text-gray-800">
            Why Choose Adbrusher Media as Your Email Marketing Partner?
          </h2>
          <p className="text-lg mb-6 text-center leading-relaxed">
            When you choose Adbrusher Media as your email marketing company, you
            gain a dedicated team focused on your success. Here’s why we stand
            out:
          </p>
          <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-4 gap-10">
  <div className="p-6 bg-white shadow-lg rounded-lg">
    <h3 className="font-bold text-xl text-center">Expertise You Can  Trust:</h3>
    <p className="text-center">
       Our email marketing specialists bring years of experience in crafting effective campaigns tailored to your business goals.
    </p>
  </div>
  <div className="p-6 bg-white shadow-lg rounded-lg">
    <h3 className="font-bold text-xl text-center"> Custom Solutions for Every Business:</h3>
    <p className="text-center">
      We recognize that each business is unique. Our email marketing services are designed to align with your specific objectives and target audience.
    </p>
  </div>
  <div className="p-6 bg-white shadow-lg rounded-lg">
    <h3 className="font-bold text-xl text-center">Transparent Commnication: </h3>
    <p className="text-center">
     We prioritize clear communication, providing regular updates on campaign performance to keep you informed every step of the way.
    </p>
  </div>
  <div className="p-6 bg-white shadow-lg rounded-lg">
    <h3 className="font-bold text-xl text-center">Proven Strategies for Success</h3>
    <p className="text-center">
      Our data-driven approach allows us to implement best practices that optimize your campaigns for maximum impact.
    </p>
  </div>
</div>


        </section>
      </div>
    </div>
  );
};

export default EmailMarketing;
