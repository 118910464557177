import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Blog1 = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="bg-gray-100 text-gray-800">
      {/* Banner Section */}
      <div className="relative">
        <img
          className="w-full h-[300px] md:h-[400px] lg:h-[600px] object-cover"
          src="https://ik.imagekit.io/7uve7qsipm/responsive-web-design-1-61f01c4b64c5f-sej-1024x538.png?updatedAt=1727439240093"
          alt="Digital Marketing Insights Banner"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center px-4">
          <h1 className="text-white text-3xl md:text-4xl lg:text-5xl font-bold text-center max-w-4xl leading-tight">
          Services Of  Digital Marketing
          </h1>
        </div>
      </div>

      {/* Content Section */}
      <div className="max-w-5xl mx-auto p-4 md:p-6 lg:p-12">
        {/* Introduction */}
        <p
          data-aos="fade-up"
          className="text-base md:text-lg lg:text-xl mb-4 md:mb-6 leading-relaxed"
        >
          In today's digital world, having a strong online presence is essential for businesses to succeed. Thankfully, digital marketing services can boost your online visibility and highlight your brand . By using different strategies and platforms, digital marketing allows you to connect with your target audience in a meaningful and engaging way. From search engine optimization (SEO) to social media advertising, digital marketing includes  a wide range of tactics to increase website traffic , improve conversions, and ultimately inhance your profile. It enables you to reach a global audience, target specific demographics, and measure the success of your campaigns with real-time analytics. By using the power of digital marketing services, you can level the business and reach worldwide . Whether you're a small business owner or an established brand, investing in digital marketing is essential for staying relevant in today's competitive marketplace. Here are types of digital marketing services 



        </p>

        {/* Divider */}
        <hr className="my-6 md:my-8 border-gray-300" />

        {/* Section 1: Stay Ahead of the Curve */}
        <h2 data-aos="fade-up" className="text-2xl md:text-3xl font-semibold mb-3 md:mb-4">
        Search engine optimization (SEO) services
        </h2>
        <p data-aos="fade-up" className="text-base md:text-lg mb-4 md:mb-6 leading-relaxed">
        Search Engine Optimisation (SEO) is a balancing act of different processes to get your site organic rankings through incorporating keywords on Search Engine Result Page (SERP). It is about the creating of the analytical framework and devising website architecture, content on the website, the business you do and the media formats you use. By delivering the best analytical insights and practices, We help you sail through the tides of your online business. Best policies will be adopted through targeted keywords, content strategies, social media you use and others and built the reputable businesses
         </p>

        {/* Section 2: Expert Tips for Success */}
        <h2 data-aos="fade-up" className="text-2xl md:text-3xl font-semibold mb-3 md:mb-4">
        Social media marketing services
        </h2>
        <p data-aos="fade-up" className="text-base md:text-lg mb-4 md:mb-6 leading-relaxed">
        Using social media in marketing does more than improve site traffic and increase your reach. It turns your business into a personality that your audience can communicate and connect .a solid social media marketing strategy requires multiple platforms and often a mix of organic and paid methods.  the more you grow your audience using social media,mpre [profit you will get , With people naturally following you and promoting your content, there will be no need for pitching. And with this approach, you’ll achieve not just your business goals but  all the other unseen benefits that lead to satisfaction and fulfillment.
         </p>
        {/* Section 3: Innovative Strategies for Growth */}
        <h2 data-aos="fade-up" className="text-2xl md:text-3xl font-semibold mb-3 md:mb-4">
        Email marketing services
        </h2>
        <p data-aos="fade-up" className="text-base md:text-lg mb-4 md:mb-6 leading-relaxed">
        It is much easier to pitch products and services through email that any other form of marketing , it helps businesses build strong relationships with prospects and customers all while moving them through the buyer's journey and maintaining strong communication throughout. Email marketing requires very little upfront investment as the only startup cost is the price of the email platform you use,it’s a very personal form of communication as your message sits directly inside the recipient’s inbox, along with messages from friends and colleagues.
        </p>

        {/* Section 4: Join the Conversation */}
        <h2 data-aos="fade-up" className="text-2xl md:text-3xl font-semibold mb-3 md:mb-4">
        Website design and development services
        </h2>
        <p data-aos="fade-up" className="text-base md:text-lg leading-relaxed">
        having a website that looks good and works well on all devices is crucial. With many people browsing on mobile devices, it’s not enough to focus only on desktops. Responsive web design makes sure your site adapts to different screen sizes, whether on a computer, tablet, or smartphone. This creates a better experience for users, encouraging them to stay and explore your content Managing a single responsive site is cost-effective, saving you time and money compared to running separate sites for desktop and mobile, responsive design keeps your site relevant as new devices are released, ensuring it always looks great. In short, investing in responsive web design is essential for reaching and connecting with your audience effectively.
         </p>

        {/* Divider */}
        <hr className="my-6 md:my-8 border-gray-300" />
      </div>
    </div>
  );
};

export default Blog1;
