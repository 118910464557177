import React from 'react';
import { Link } from 'react-router-dom';

const ServicesWidget = () => {
    const services = [
        {
            title: 'Mobile App Development',
            description:
                'We create user-friendly mobile applications that enhance customer engagement and streamline business operations. Our apps are tailored to meet your specific needs, ensuring a seamless experience for our users.',
            image: 'https://ik.imagekit.io/zfayt6inj/wwww.png?updatedAt=1727195262706',
            color: 'bg-gradient-to-r from-purple-400 to-pink-600 ...',
            path: '/services/mobile-app-development',
        },
        {
            title: 'Website Development',
            description:
                'Our team builds responsive, visually appealing websites that not only attract visitors but also convert them into customers. We focus on functionality and design, ensuring our site reflects your brand’s identity.',
            image: 'https://ik.imagekit.io/zfayt6inj/wwww.png?updatedAt=1727195262706',
            color: 'bg-gradient-to-r from-purple-400 to-pink-600 ...',
            path: '/services/website-development',
        },
        {
            title: 'SEO Marketing',
            description:
                'We optimize your online presence through targeted SEO strategies that improve your visibility on search engines. Our approach helps you reach your audience effectively, driving organic traffic to our website.',
            image: 'https://ik.imagekit.io/zfayt6inj/seo.png?updatedAt=1727195463565',
            color: 'bg-gradient-to-r from-purple-400 to-pink-600 ...',
            path: '/services/seo-marketing',
        },
        {
            title: 'PPC Ads Campaign',
            description:
                'We manage pay-per-click advertising campaigns that deliver measurable results. Our targeted approach ensures that our ads reach the right people at the right time, maximizing your return on investment.',
            image: 'https://ik.imagekit.io/zfayt6inj/social.png?updatedAt=1727195463707',
            color: 'bg-gradient-to-r from-purple-400 to-pink-600 ...',
            path: '/services/ppc-ads-campaign',
        },
        {
            title: 'Content Branding',
            description:
                'We help you establish a strong brand identity through compelling content. Our content branding services focus on storytelling and messaging that connect with our audience and build trust.',
            image: 'https://ik.imagekit.io/zfayt6inj/social.png?updatedAt=1727195463707',
            color: 'bg-gradient-to-r from-purple-400 to-pink-600 ...',
            path: '/services/content-branding',
        },
        {
            title: 'Email Marketing',
            description:
                'Our email marketing strategies are designed to nurture leads and maintain customer relationships. We create targeted campaigns that keep our audience informed and engaged, driving conversions.',
            image: 'https://ik.imagekit.io/zfayt6inj/social.png?updatedAt=1727195463707',
            color: 'bg-gradient-to-r from-purple-400 to-pink-600 ...',
            path: '/services/email-marketing',
        },
        {
            title: 'Digital Marketing',
            description:
                'Our digital marketing strategies ensure a comprehensive approach that includes SEO, PPC, content, and email marketing to drive engagement and conversion.',
            image: 'https://ik.imagekit.io/zfayt6inj/social.png?updatedAt=1727195463707',
            color: 'bg-gradient-to-r from-purple-400 to-pink-600 ...',
            path: '/services/digital-marketing',
        },
        {
            title: 'Social Media Creative & Strategy',
            description:
                'Our experts craft engaging social media campaigns that resonate with our audience. We develop creative content and strategic plans that increase brand awareness and foster community engagement.',
            image: 'https://ik.imagekit.io/zfayt6inj/social.png?updatedAt=1727195463707',
            color: 'bg-gradient-to-r from-purple-400 to-pink-600 ...',
            path: '/services/social-media-strategy',
        },
    ];

    return (
        <div className=''>
            <div className="bg-white text-black py-12 px-8 sm:px-6 lg:px-32">
                <div className="flex flex-col items-center">
                    
                    <h2 className=" text-center text-3xl md:text-4xl font-extrabold mb-6" data-aos="slide-down" data-aos-duration="300">
                        Our Services
                    </h2>
                    <p className="mb-8 text-center text-m ">
                        We understand that every brand comes with its own unique challenges, and hence requires expertise.
                        At Adbrusher Media, we offer a comprehensive range of services designed to elevate our brand and drive results.
                    </p>
                </div>

                <div className="grid grid-cols-1 rounded-lg  md:grid-cols-2 gap-8 max-w-7xl mx-auto ">
                    {services.map((service, index) => (
                        <div
                            key={index}
                            className={`p-6 flex flex-col items-start ${service.color} min-h-[200px] rounded-lg shadow-md`}
                            data-aos="fade-up"
                            data-aos-delay={index * 50}
                            data-aos-duration="500"
                        >
                            <div className="flex justify-between items-center w-full mb-4">
                                <h3 className="text-2xl md:text-3xl font-bold">{service.title}</h3>
                                <img
                                    src={service.image}
                                    alt={service.title}
                                    className="w-10 h-10 md:w-12 md:h-12 object-cover"
                                />
                            </div>
                            <p className="mb-6 text-left text-gray-200">{service.description}</p>
                            <Link
                                to={service.path}
                                className="bg-white text-black px-4 py-2 rounded-lg font-bold hover:bg-gray-200 transition duration-300"
                            >
                                Get Started
                            </Link>
                        </div>
                    ))}
                </div>

                {/* Let's Work Together Section */}
                <div className="bg-cover text-black py-12 mt-16 flex flex-col lg:flex-row items-center justify-between max-w-7xl mx-auto" data-aos="fade-up" data-aos-duration="500">
                    <div className="w-full lg:w-1/2 mb-8 lg:mb-0">
                        <img src="https://ik.imagekit.io/tdlebsr5e/2148431343.jpg?updatedAt=1729676998018" alt="Let's Work Together" className="shadow-md rounded-none" />
                    </div>
                    <div className="w-full lg:w-1/2 lg:pl-10">
                        <h3 className="text-black text-sm font-semibold mb-2">C O N T A C T</h3>
                        <h2 className="text-3xl md:text-4xl font-bold mb-4">Let’s Work Together!</h2>
                        <p className="text-black mb-6 items-start justify-items-start py-4 ">
                            Join forces with us to unlock the extraordinary. With our innovative strategies and out-of-the-box thinking,
                            together we’ll create a one-of-a-kind marketing experience that sets your brand apart.
                            Get in touch today and let’s embark on a journey of unrivaled success.
                        </p>
                        <Link
                            to="/contact"
                            className="bg-pink-600 text-white px-5 py-3 rounded-lg font-bold hover:bg-pink-800 transition duration-300"
                        >
                            Contact Us Now
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServicesWidget;
