import React from "react";
import { Link } from "react-router-dom";

const isMobile = () => {
  return window.innerWidth <= 768; // Adjust this value based on your breakpoints
};

const PrivacyPolicy = () => {
  return (
    <div>
      {/* Top Section with Background Image and Blue Tint */}
      <div
        className="relative h-1/2 py-32 flex items-center justify-start bg-cover bg-center px-4 sm:px-8 md:px-16 lg:px-32 lg:py-64"
        style={{
          backgroundImage: `url('https://ik.imagekit.io/7uve7qsipm/pexels-divinetechygirl-1181354.jpg?updatedAt=1727328203546')`,
        }}
        data-aos="slide-in"
      >
        <div className="absolute inset-0 bg-black bg-opacity-80"></div>
        <div className="absolute inset-0 bg-blue-800 bg-opacity-10"></div>

        <div className="relative z-10 text-left">
          <h1 className="text-4xl sm:text-5xl md:text-8xl text-white font-bold"
          data-aos="zoom-in-left"
          data-aos-anchor="#example-anchor"
          data-aos-offset="500"
          data-aos-duration="500">
            Privacy Policy
          </h1>
          <p className="text-white mt-4 sm:mt-6 font-semibold text-sm sm:text-lg md:text-xl">
            <Link to="/" className="text-yellowcustom hover:underline">
              Home
            </Link>{" "}
            / Privacy Policy
          </p>
        </div>
      </div>

      {/* Privacy Policy Content */}
      <div className="container mx-auto py-8 px-4 sm:px-8 md:px-12 lg:px-20">
        <div className="text-left">
          <h2 className="text-pink-500 font-bold text-base md:text-lg my-2 md:my-4">
            Overview of Privacy Policy
          </h2>
          <p className="text-sm sm:text-base md:text-lg text-gray-700 mb-4">
            This privacy policy explains how we collect, use, and protect your
            personal information when you use our website. We value your privacy
            and are committed to protecting your personal data.
          </p>

          <h2 className="text-pink-500 font-bold text-base md:text-lg my-2 md:my-4">
            Information We Collect
          </h2>
          <p className="text-sm sm:text-base md:text-lg text-gray-700 mb-4">
            We collect information that you provide to us directly, such as your
            name, email address, and any other details you submit when filling
            out forms on our website.
          </p>

          <h2 className="text-pink-500 font-bold text-base md:text-lg my-2 md:my-4">
            How We Use Your Information
          </h2>
          <p className="text-sm sm:text-base md:text-lg text-gray-700 mb-4">
            The information we collect is used to provide, maintain, and improve
            our services. We may also use your data to communicate with you,
            process transactions, and send updates about our services.
          </p>

          <h2 className="text-pink-500 font-bold text-base md:text-lg my-2 md:my-4">
            Protecting Your Information
          </h2>
          <p className="text-sm sm:text-base md:text-lg text-gray-700 mb-4">
            We implement various security measures to protect your personal
            information from unauthorized access, disclosure, or destruction.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
