import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const OurWork = () => {
  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
      easing: "ease-out",
      offset: 100,
    });
  }, []);

  // State to handle screen size
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="p-4 md:p-8 max-w-screen-xl mx-auto text-start">
    {/* Header Section */}
    <div className="flex flex-col items-center text-center md:py-16" data-aos="slide-up">
      {/* Centered Text */}
      <div className="max-w-[80%]">
        <h2 className="text-3xl md:text-5xl font-bold">Redefining Rules, Without Breaking Them!</h2>
        <p className="text-gray-600 text-sm md:text-md my-6">
          Working with a top digital marketing agency means you have the expertise to navigate the digital world and help your business grow.
        </p>
      </div>
    </div>
  
    {/* Work Sections */}
    <div className="flex flex-col items-center justify-center space-y-12">
      {/* Section 1 */}
      <div className="flex flex-col md:flex-row items-center md:space-x-8">
        {/* Image */}
        <div className="w-full h-[25rem] md:max-w-[40%] mb-4 md:mb-0" data-aos="slide-up">
          <img
            className="w-full h-full object-cover"
            src="https://ik.imagekit.io/tdlebsr5e/137640.jpg?updatedAt=1729677124583"
            alt="Work Example 1"
          />
        </div>
        <div className="w-full md:max-w-[50%] h-full bg-gradient-to-r from-purple-400 to-pink-600 text-white p-6 md:p-10 flex flex-col justify-center" data-aos="slide-up">
          <h3 className="text-3xl md:text-4xl font-semibold mb-6">Planning</h3>
          <p className="text-black text-md md:text-lg mb-8 justify-left">
            Every successful digital marketing agency starts with careful planning. This means researching your target audience, understanding your competitors, and defining your goals clearly. This foundational work sets you up for success.
          </p>
        </div>
      </div>
  
      {/* Section 2 */}
      <div className="flex flex-col md:flex-row-reverse items-center md:space-x-reverse md:space-x-8">
        {/* Image */}
        <div className="w-full h-[25rem] md:max-w-[40%] mb-4 md:mb-0" data-aos="slide-up">
          <img
            className="w-full h-full object-cover"
            src="https://ik.imagekit.io/tdlebsr5e/132300.jpg?updatedAt=1729677296086"
            alt="Work Example 2"
          />
        </div>
        <div className="w-full md:max-w-[50%] h-full bg-gradient-to-r from-purple-400 to-pink-600 p-6 md:p-10 flex flex-col justify-center" data-aos="slide-up">
          <h3 className="text-white text-3xl md:text-4xl font-semibold mb-6">Strategy</h3>
          <p className="text-black text-md md:text-lg mb-8 justify-left">
            Next, we develop a strategy to connect with your audience and achieve your goals. Choosing the right digital marketing channels like social media, email, or search engines is crucial for maximizing your impact.
          </p>
        </div>
      </div>
  
      {/* Additional sections */}
      {/* Add the remaining sections here with similar structure */}
      {/* Section3 */}
      <div className="flex flex-col md:flex-row items-center md:space-x-8">
        {/* Image */}
        <div className="w-full h-[25rem] md:max-w-[40%] mb-4 md:mb-0" data-aos="slide-up">
          <img
            className="w-full h-full object-cover"
            src="https://ik.imagekit.io/tdlebsr5e/1411.jpg?updatedAt=1729497933620"
            alt="Work Example 1"
          />
        </div>
        <div className="w-full md:max-w-[50%] h-full bg-gradient-to-r from-purple-400 to-pink-600 text-white p-6 md:p-10 flex flex-col justify-center" data-aos="slide-up">
          <h3 className="text-3xl md:text-4xl font-semibold mb-6">Execution</h3>
          <p className="text-black text-md md:text-lg mb-8">
          This is where we put your plan into action! We launch creative campaigns designed to engage your audience and keep your brand visible. Our team ensures everything runs smoothly and effectively.


          </p>
        </div>
      </div>

      <div className="flex flex-col md:flex-row-reverse items-center md:space-x-reverse md:space-x-8">
        {/* Image */}
        <div className="w-full h-[25rem] md:max-w-[40%] mb-4 md:mb-0" data-aos="slide-up">
          <img
            className="w-full h-full object-cover"
            src="https://ik.imagekit.io/tdlebsr5e/2148894074.jpg?updatedAt=1727434566988"
            alt="Work Example 2"
          />
        </div>
        <div className="w-full md:max-w-[50%] h-full bg-gradient-to-r from-purple-400 to-pink-600 p-6 md:p-10 flex flex-col justify-center" data-aos="slide-up">
          <h3 className="text-white text-3xl md:text-4xl font-semibold mb-6">Repeat

</h3>
          <p className="text-black text-md md:text-lg mb-8">
          Once we’ve achieved your desired results, we can repeat and refine the process. This ongoing cycle allows us to continually improve your strategy for even better outcomes.
          </p>
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center md:space-x-8">
        {/* Image */}
        <div className="w-full h-[25rem] md:max-w-[40%] mb-4 md:mb-0" data-aos="slide-up">
          <img
            className="w-full h-full object-cover"
            src="https://ik.imagekit.io/6oa6qejxe/2147650959.jpg?updatedAt=1728304011893"
            alt="Work Example 1"
          />
        </div>
        <div className="w-full md:max-w-[50%] h-full bg-gradient-to-r from-purple-400 to-pink-600 text-white p-6 md:p-10 flex flex-col justify-center" data-aos="slide-up">
          <h3 className="text-3xl md:text-4xl font-semibold mb-6">Refinement</h3>
          <p className="text-black text-md md:text-lg mb-8">
          After reviewing your campaign results, we make necessary adjustments. This might involve updating your website, enhancing social media content, or tweaking your ads to stay relevant and effective.


          </p>
        </div>
      </div>
      <div className="flex flex-col md:flex-row-reverse items-center md:space-x-reverse md:space-x-8">
        {/* Image */}
        <div className="w-full h-[25rem] md:max-w-[40%] mb-4 md:mb-0" data-aos="slide-up">
          <img
            className="w-full h-full object-cover"
            src="https://ik.imagekit.io/tdlebsr5e/2147707796.jpg?updatedAt=1727261403541"
            alt="Work Example 2"
          />
        </div>
        <div className="w-full md:max-w-[50%] h-full bg-gradient-to-r from-purple-400 to-pink-600 p-6 md:p-10 flex flex-col justify-center" data-aos="slide-up">
          <h3 className="text-white text-3xl md:text-4xl font-semibold mb-6">Tracking and Analysis



</h3>
          <p className="text-black text-md md:text-lg mb-8">
          Monitoring results is key to success. We assess what’s working and what isn’t, making changes as needed to improve your performance. Our data-driven approach helps ensure your campaigns consistently meet their goals.
          </p>
        </div>
      </div>



    </div>
  </div>
  
  );
};

export default OurWork;
