import React from "react";
import { Link } from "react-router-dom";

const isMobile = () => {
  return window.innerWidth <= 768; // Adjust this value based on your breakpoints
};

const CookiePolicy = () => {
  return (
    <div>
      {/* Top Section with Background Image and Blue Tint */}
      <div
        className="relative h-1/2 py-32 flex items-center justify-start bg-cover bg-center px-4 sm:px-8 md:px-16 lg:px-32 lg:py-64"
        style={{
          backgroundImage: `url('https://ik.imagekit.io/7uve7qsipm/pexels-divinetechygirl-1181354.jpg?updatedAt=1727328203546')`,
        }}
        data-aos="slide-in"
      >
        <div className="absolute inset-0 bg-black bg-opacity-80"></div>
        <div className="absolute inset-0 bg-blue-800 bg-opacity-10"></div>

        <div className="relative z-10 text-left">
          <h1 className="text-4xl sm:text-5xl md:text-8xl text-white font-bold"
          data-aos="zoom-in-left"
          data-aos-anchor="#example-anchor"
          data-aos-offset="500"
          data-aos-duration="500">
            Cookie Policy
          </h1>
          <p className="text-white mt-4 sm:mt-6 font-semibold text-sm sm:text-lg md:text-xl">
            <Link to="/" className="text-yellowcustom hover:underline">
              Home
            </Link>{" "}
            / Cookie Policy
          </p>
        </div>
      </div>

      {/* Cookie Policy Content */}
      <div className="container mx-auto py-8 px-4 sm:px-8 md:px-12 lg:px-20">
        <div className="text-left">
          <h2 className="text-pink-500 font-bold text-base md:text-lg my-2 md:my-4">
            Our Use of Cookies
          </h2>
          <p className="text-sm sm:text-base md:text-lg text-gray-700 mb-4">
            We use cookies to enhance your experience on our website. By using
            our site, you agree to the use of cookies in accordance with our
            Cookie Policy. This policy explains what cookies are, how we use
            them, and how you can manage your cookie preferences.
          </p>

          <h2 className="text-pink-500 font-bold text-base md:text-lg my-2 md:my-4">
            What Are Cookies?
          </h2>
          <p className="text-sm sm:text-base md:text-lg text-gray-700 mb-4">
            Cookies are small text files that are placed on your device by
            websites you visit. They are used to make websites work more
            efficiently and to provide information to the owners of the site.
          </p>

          <h2 className="text-pink-500 font-bold text-base md:text-lg my-2 md:my-4">
            Managing Your Cookies
          </h2>
          <p className="text-sm sm:text-base md:text-lg text-gray-700 mb-4">
            You can control how cookies are used on your device by adjusting the
            settings in your web browser. You can also delete cookies that are
            already on your device.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CookiePolicy;
