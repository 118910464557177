import React from "react";
import { Link } from "react-router-dom";

const isMobile = () => {
  return window.innerWidth <= 768; // Adjust this value based on your breakpoints
};

const TermsAndConditions = () => {
  return (
    <div>
      {/* Top Section with Background Image and Blue Tint */}
      <div
        className="relative h-1/2 py-32 flex items-center justify-start bg-cover bg-center px-4 sm:px-8 md:px-16 lg:px-32 lg:py-64"
        style={{
          backgroundImage: `url('https://ik.imagekit.io/7uve7qsipm/pexels-divinetechygirl-1181354.jpg?updatedAt=1727328203546')`,
        }}
        data-aos="slide-in"
      >
        <div className="absolute inset-0 bg-black bg-opacity-80"></div>
        <div className="absolute inset-0 bg-blue-800 bg-opacity-10"></div>

        <div className="relative z-10 text-left">
          <h1 className="text-4xl sm:text-5xl md:text-8xl text-white font-bold"
          data-aos="zoom-in-left"
          data-aos-anchor="#example-anchor"
          data-aos-offset="500"
          data-aos-duration="500">
            Terms and Conditions
          </h1>
          <p className="text-white mt-4 sm:mt-6 font-semibold text-sm sm:text-lg md:text-xl">
            <Link to="/" className="text-yellowcustom hover:underline">
              Home
            </Link>{" "}
            / Terms and Conditions
          </p>
        </div>
      </div>

      {/* Terms and Conditions Content */}
      <div className="container mx-auto py-8 px-4 sm:px-8 md:px-12 lg:px-20">
        <div className="text-left">
          <h2 className="text-pink-500 font-bold text-base md:text-lg my-2 md:my-4">
            Introduction
          </h2>
          <p className="text-sm sm:text-base md:text-lg text-gray-700 mb-4">
            These terms and conditions outline the rules and regulations for the
            use of our website. By accessing this website, you accept these
            terms and conditions in full.
          </p>

          <h2 className="text-pink-500 font-bold text-base md:text-lg my-2 md:my-4">
            Intellectual Property Rights
          </h2>
          <p className="text-sm sm:text-base md:text-lg text-gray-700 mb-4">
            Other than the content you own, all intellectual property rights and
            materials contained in this website are owned by us or our
            licensors.
          </p>

          <h2 className="text-pink-500 font-bold text-base md:text-lg my-2 md:my-4">
            Restrictions
          </h2>
          <p className="text-sm sm:text-base md:text-lg text-gray-700 mb-4">
            You are specifically restricted from publishing any website material
            in any other media, selling or commercializing any website material,
            using this website in any way that is harmful, and more.
          </p>

          <h2 className="text-pink-500 font-bold text-base md:text-lg my-2 md:my-4">
            Governing Law
          </h2>
          <p className="text-sm sm:text-base md:text-lg text-gray-700 mb-4">
            These terms will be governed by and interpreted in accordance with
            the laws of our country, and you submit to the non-exclusive
            jurisdiction of the state and federal courts for the resolution of
            any disputes.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
