import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const Blogs = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div
      className="md:p-16 max-w-full md:px-40 text-center bg-white"
      style={{ minHeight: "600px" }}
    >
      <div className="p-6  mt-20 md:text-center">
        <h2 className="text-3xl text-black md:text-6xl font-bold text-center">
          Latest Blog & News
        </h2>
        <p className="text-black text-sm md:text-md mt-3">
          Stay informed and inspired with our latest blog and news updates,
          delivering fresh insights and captivating stories.
        </p>
      </div>
      {/* Header Section */}
      <div
        className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center mb-12"
        data-aos="slide-up"
      ></div>

      {/* Cards Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 w-full p-6 md:p-0">
  {/* Card 1 */}
  <Link
    to="/Blogs/Blog1"
    className="bg-gradient-to-r from-purple-200 to-pink-500 shadow-lg rounded-lg overflow-hidden transition-transform transform hover:scale-105 h-[420px] p-5"
    data-aos="slide-up"
  >
    <img
      className="w-full h-48 object-cover"
      src="https://ik.imagekit.io/tdlebsr5e/167088.jpg?updatedAt=1729148992800"
      alt="The benefits of having a responsive website design"
    />
    <div className="pt-4 px-4 pb-6">
      <h3 className="text-lg font-semibold text-gray-800 text-center">
        Services Of Digital Marketing
      </h3>
      <p className="text-sm text-gray-600 text-center mt-2">
        Discover the key digital marketing services that can help grow
        your business online. Learn how SEO, content marketing, and social
        media can elevate your online presence.
      </p>
    </div>
  </Link>

  {/* Card 2 */}
  <Link
    to="/Blogs/Blog2"
    className="bg-gradient-to-r from-purple-200 to-pink-500 shadow-lg rounded-lg overflow-hidden transition-transform transform hover:scale-105 h-[420px] p-5"
    data-aos="slide-up"
  >
    <img
      className="w-full h-48 object-cover"
      src="https://ik.imagekit.io/tdlebsr5e/2150208239.jpg?updatedAt=1731391220141"
      alt="Difference between static website and dynamic website"
    />
    <div className="pt-4 px-4 pb-6">
      <h3 className="text-lg font-semibold text-gray-800 text-center">
        Your Go-To Hub for Digital Marketing Insights
      </h3>
      <p className="text-sm text-gray-600 text-center mt-2">
        Stay ahead with insights on the latest trends and strategies in
        digital marketing, including tips for engaging your audience and
        maximizing ROI.
      </p>
    </div>
  </Link>

  {/* Card 3 */}
  <Link
    to="/Blogs/Blog3"
    className="bg-gradient-to-r from-purple-200 to-pink-500 shadow-lg rounded-lg overflow-hidden transition-transform transform hover:scale-105 h-[420px] p-5"
    data-aos="slide-up"
  >
    <img
      className="w-full h-48 object-cover"
      src="https://ik.imagekit.io/tdlebsr5e/94833.jpg?updatedAt=1731391429799"
      alt="10 key features of ecommerce website"
    />
    <div className="pt-4 px-4 pb-6">
      <h3 className="text-lg font-semibold text-gray-800 text-center">
        Importance of Digital Marketing for Businesses
      </h3>
      <p className="text-sm text-gray-600 text-center mt-2">
        Learn why digital marketing is essential for business growth in
        today's world. Find out how it can boost customer engagement,
        drive sales, and improve brand loyalty.
      </p>
    </div>
  </Link>
</div>

    </div>
  );
};

export default Blogs;
