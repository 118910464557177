import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const FormData = () => {
  const [contactData, setContactData] = useState([]);
  const [joinData, setJoinData] = useState([]);
  const [loadingContact, setLoadingContact] = useState(true);
  const [loadingJoin, setLoadingJoin] = useState(true);
  const [errorContact, setErrorContact] = useState(null);
  const [errorJoin, setErrorJoin] = useState(null);

  // Fetching contact form data
  useEffect(() => {
    const fetchContactData = async () => {
      try {
        const response = await axios.get("https://api.adbrusher.com/api/contacts"); // Replace with your actual API endpoint
        setContactData(response.data);
      } catch (error) {
        setErrorContact(error.message);
      } finally {
        setLoadingContact(false);
      }
    };

    fetchContactData();
  }, []);

  // Fetching join form data
  useEffect(() => {
    const fetchJoinData = async () => {
      try {
        const response = await axios.get("https://api.adbrusher.com/api/join-us"); // Replace with your actual API endpoint
        setJoinData(response.data);
      } catch (error) {
        setErrorJoin(error.message);
      } finally {
        setLoadingJoin(false);
      }
    };

    fetchJoinData();
  }, []);

  return (
    <div className="">
      <div
        className="relative h-1/2 py-32 flex items-center justify-start bg-cover bg-center px-4 sm:px-8 md:px-16 lg:px-32 lg:py-34"
        style={{
          backgroundImage: `url('https://images.pexels.com/photos/1181406/pexels-photo-1181406.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')`,
        }}
        data-aos="slide-in"
      >
        <div className="absolute inset-0 bg-black bg-opacity-80"></div>
        <div className="absolute inset-0 bg-blue-800 bg-opacity-10"></div>

        <div className="relative z-10 text-left">
          <h1
            className="text-4xl sm:text-5xl md:text-7xl text-white font-bold"
            data-aos="zoom-in-left"
            data-aos-offset="500"
            data-aos-duration="500"
          >
            Form Data
          </h1>
          <p className="text-white mt-6 font-semibold text-lg md:text-2xl">
            <Link to="/" className="text-yellowcustom hover:underline">
              Home
            </Link>{" "}
            / FormData
          </p>
        </div>
      </div>

      <div className="container mx-auto px-6 py-12">
        {/* Contact Form Data Section */}
        <div className="mb-10 p-8 bg-white shadow-lg rounded-lg transition-transform transform hover:scale-105 hover:shadow-xl duration-300 ease-in-out">
          <h2 className="text-3xl font-semibold mb-4">Contact Form Data</h2>
          {loadingContact && (
            <p className="text-lg text-gray-600">Loading contact data...</p>
          )}
          {errorContact && <p className="text-red-500">{errorContact}</p>}
          {!loadingContact && contactData.length === 0 && (
            <p>No contact data available.</p>
          )}
          {!loadingContact && contactData.length > 0 && (
            <div className="flex flex-wrap gap-4">
              {contactData.map((item, index) => (
                <div
                  key={index}
                  className="bg-gray-100 p-4 rounded-md flex-1 min-w-[300px] shadow-md"
                >
                  <strong>Name:</strong> {item.name} <br />
                  <strong>Email:</strong> {item.email} <br />
                  <strong>Phone:</strong> {item.phone} <br />
                  <strong>Website:</strong> {item.website} <br />
                  <strong>Message:</strong> {item.message} <br />
                  <strong>Consent:</strong> {item.consent ? "Yes" : "No"} <br />
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Join Form Data Section */}
        <div className="p-8 bg-white shadow-lg rounded-lg transition-transform transform hover:scale-105 hover:shadow-xl duration-300 ease-in-out">
          <h2 className="text-3xl font-semibold mb-4">Join Form Data</h2>
          {loadingJoin && (
            <p className="text-lg text-gray-600">Loading join data...</p>
          )}
          {errorJoin && <p className="text-red-500">{errorJoin}</p>}
          {!loadingJoin && joinData.length === 0 && (
            <p>No join data available.</p>
          )}
          {!loadingJoin && joinData.length > 0 && (
            <div className="flex flex-wrap gap-4">
              {joinData.map((item, index) => (
                <div
                  key={index}
                  className="bg-gray-100 p-4 rounded-md flex-1 min-w-[300px] shadow-md"
                >
                  <strong>Name:</strong> {item.name} <br />
                  <strong>Email:</strong> {item.email} <br />
                  <strong>Resume:</strong>{" "}
                  <a
                    href={`http://localhost:5000/${item.resume}`} // Adjust path as necessary
                    download
                    className="text-blue-500 hover:underline"
                  >
                    Download
                  </a>{" "}
                  <br />
                  <strong>Message:</strong> {item.message} <br />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FormData;
