import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCompass,
  faDesktop,
  faPaintBrush,
  faCode,
  faRocket,
} from "@fortawesome/free-solid-svg-icons";
const MobileAppDevelopment = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  return (
    <div>
      {/* Top Section with Background Image and Blue Tint */}
      <div
        className="relative h-screen py-32 flex items-center justify-start bg-cover bg-center px-4 sm:px-8 md:px-16 lg:px-32 lg:py-64"
        style={{
          backgroundImage: `url('https://ik.imagekit.io/tdlebsr5e/2150104516.jpg?updatedAt=1731391953576')`,
        }}
        data-aos="slide-in"
      >
        {/* <div className="absolute inset-0 bg-black bg-opacity-70"></div>
        <div className="absolute inset-0 bg-blue-800 bg-opacity-10"></div>
        <div className="relative z-10 text-left max-w-xl">
          <h1
            className="text-5xl sm:text-6xl md:text-6xl text-white font-bold"
            data-aos="zoom-in-left"
          >
             Mobile App Development
          </h1> */}
          {/* <p className="text-sky-400 my-6 font-semibold text-lg md:text-xl tracking-wider">
            YOUR VISION, YOUR EXPERTISE
          </p> */}
          {/* <p className="text-white text-lg md:text-xl leading-relaxed">
          At Adbrusher Media, we are dedicated to creating innovative, user-friendly mobile applications that engage and retain users.
          </p>
        </div> */}
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative z-10 text-left p-8">
          <h1
            className="text-5xl md:text-7xl text-white font-bold"
            data-aos="zoom-in-left"
          >
              App Development
          </h1>
          <p className="text-sky-400 my-6 font-semibold text-lg tracking-wider">
          Your Vision, Our Expertise

        Premier App Development Company
          </p>
        </div>
      </div>

      <div className="bg-white text-black">
      <div className=" bg-gradient-to-r from-purple-300 to-pink-200">
      <h1 className="text-4xl font-bold text-center py-7">
      Our Comprehensive App Development Services
            </h1>
        {/* Introduction Section */}
         {/* <section
  className="
    py-16 
    px-4 sm:px-16 xl:px-64 
    flex flex-col md:flex-row gap-8
    justify-between items-center 
    bg-gradient-to-r from-purple-300 to-pink-200
  "
> */}

         
        <section className="    bg-gradient-to-r from-purple-300 to-pink-200 py-10  text-center bg-gray-50">
       

          <div className="flex flex-wrap justify-center gap-8 mx-auto">
            {/* Discovery and Planning */}
            <div className="flex flex-col text-justify max-w-xs text-center p-8 bg-white border border-gray-200 rounded-lg">
              <FontAwesomeIcon
                icon={faDesktop}
                className="text-5xl mb-4 text-blue-600"
              />
              <h3 className="text-2xl font-bold my-4 text-gray-800 text-center">
              Custom App Development
              </h3>
              <p className="text-md text-gray-700 text-center" >
              As a leading app development firm, we specialize in crafting tailored applications designed to fit your unique business model and user needs. Our team takes the time to understand your objectives, allowing us to build scalable and robust solutions.
              </p>
            </div>

            {/* UI/UX Design */}
            <div className="flex flex-col text-justify max-w-xs text-center p-8 bg-white border border-gray-200 rounded-lg">
  <FontAwesomeIcon
    icon={faPaintBrush}
    className="text-5xl mb-4 text-green-600"
  />
  <h3 className="text-2xl font-bold my-4 text-gray-800 text-center">
  E-commerce Solutions
  </h3>
  <p className="text-md text-gray-700 text-center leading-relaxed">
  Our robust e-commerce apps transform the shopping experience, making it seamless and enjoyable for your customers. With our expertise, your e-commerce platform will be equipped to handle everything from inventory management to customer engagement.
  </p>
</div>


            {/* Development and Testing */}
            <div className="flex flex-col text-justify max-w-xs text-center p-8 bg-white border border-gray-200 rounded-lg">
  <FontAwesomeIcon
    icon={faCode}
    className="text-5xl mb-4 text-red-600"
  />
  <h3 className="text-2xl font-bold my-4 text-gray-800 leading-snug text-center">
  Enterprise Mobility Solutions
  </h3>
  <p className="text-md text-gray-700 text-center leading-relaxed">
  Our enterprise mobility solutions are designed to optimize operations, enhance collaboration, and boost productivity. We develop applications that streamline workflows, improve communication, and facilitate data sharing across departments.
  
  </p>
</div>


            {/* Launch and Support */}
            <div className="flex flex-col text-justify max-w-xs text-center p-8 bg-white border border-gray-200 rounded-lg">
              <FontAwesomeIcon
                icon={faRocket}
                className="text-5xl mb-4 text-purple-600"
              />
              <h3 className="text-2xl font-bold my-4 text-gray-800 text-center">
              User Interface & Experience Design
              </h3>
              <p className="text-md text-gray-700 text-center">
              Our user interface and experience design services focus on creating captivating and functional designs that engage users from the moment they open your app. We employ the latest design principles to ensure that navigation is intuitive and delightful.
            
              </p>
            </div>


            {/* <div className="flex flex-col text-justify max-w-xs text-center p-8 bg-white border border-gray-200 rounded-lg">
              <FontAwesomeIcon
                icon={faRocket}
                className="text-5xl mb-4 text-purple-600"
              />
              <h3 className="text-2xl font-bold my-4 text-gray-800 text-center">
              Marketing and Growth Strategies:
              </h3>
              <p className="text-md text-gray-700 text-center">
              As part of our app development services, we offer tailored marketing and growth strategies to ensure your app reaches the right audience and achieves its full potential. We continuously analyze performance metrics to refine our approach over time.
            
              </p>
            </div> */}
          </div>
        </section>

        
</div>


        {/* Our Methodology Section */}
        <section className="py-20 px-6 bg-gradient-to-r from-blue-50 to-indigo-50 text-center">
  <h2 className="text-4xl font-extrabold mb-12 text-gray-900">
    What Sets Our App Development Services Apart in the Industry?
  </h2>

  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 max-w-6xl mx-auto relative z-10">
    {[
      { text: "Customised Solutions for Every Business", icon: "fas fa-cogs" },
      { text: "Expertise Across Multiple Platforms", icon: "fas fa-layer-group" },
      { text: "Focus on Engaging User Experiences", icon: "fas fa-users" },
      { text: "Agile Development Methodologies", icon: "fas fa-running" },
      { text: "Rigorous Quality Assurance", icon: "fas fa-shield-alt" },
      { text: "Data-Driven Insights", icon: "fas fa-chart-line" },
      { text: "Strong Focus on Security", icon: "fas fa-lock" },
      { text: "Innovative Marketing Strategies", icon: "fas fa-bullhorn" },
    ].map((item, index) => (
      <div
        key={index}
        className="bg-white p-6 shadow-lg rounded-lg transform transition-all duration-300 hover:scale-105 hover:shadow-2xl text-left flex items-center"
      >
        <span className="text-blue-600 text-3xl mr-4">
          <i className={item.icon}></i>
        </span>
        <p className="text-gray-800 font-semibold text-lg">{item.text}</p>
      </div>
    ))}
  </div>

  {/* Subtle Background Shapes */}
  <div className="absolute top-0 left-0 w-32 h-32 bg-blue-200 opacity-30 rounded-full transform -translate-x-10 -translate-y-10 animate-pulse"></div>
  <div className="absolute bottom-0 right-0 w-40 h-40 bg-indigo-200 opacity-30 rounded-full transform translate-x-10 translate-y-10 animate-pulse"></div>
</section>


        {/* Call to Action Section */}
        <section
          className="py-20 px-4 text-center  bg-gradient-to-r from-purple-300 to-pink-200 text-black"
          data-aos="fade-up"
        >
          <h2 className="text-3xl font-semibold mb-6">
            Ready to Start Your Project?
          </h2>
          <p className="text-lg mb-8">
            Contact us today to discuss how we can help you develop your dream
            app. Our team is dedicated to making your app a standout success.
          </p>
          <button className="bg-pink-600 text-white py-2 px-6 rounded-full font-bold hover:bg-purple-600 transition">
            Get Started
          </button>
        </section>
      </div>
    </div>
  );
};

export default MobileAppDevelopment;
