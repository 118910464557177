import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Blog4 = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="bg-gray-100 text-gray-800">
      {/* Banner Section */}
      <div className="relative">
        <img
          className="w-full h-[300px] md:h-[400px] lg:h-[600px] object-cover"
          src="https://ik.imagekit.io/7uve7qsipm/blog_img_05-1024x683.jpg?updatedAt=1727439240156"
          alt="Importance of Digital Marketing"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center px-4">
          <h1 className="text-white text-3xl md:text-4xl lg:text-5xl font-bold text-center max-w-4xl leading-tight">
          BENEFIT OF PAID ADVERTISEMENT 
          </h1>
        </div>
      </div>

      {/* Content Section */}
      <div className="max-w-5xl mx-auto p-4 md:p-6 lg:p-12">
        {/* Introduction */}
        <p
          data-aos="fade-up"
          className="text-base md:text-lg lg:text-xl mb-4 md:mb-6 leading-relaxed"
        >
          Paid advertisement plays a vitale role in enchaning your business and promote your brand ,it help you to increase traffic, sales and conversion through click.Paid advertising is a must-have for small startup businesses as this form of advertising . Websites take some time to rank organically, whereas paid ads provide you with the opportunity to appear higher on search engines from the start. 
        </p>
        {/* <p
          data-aos="fade-up"
          className="text-base md:text-lg lg:text-xl mb-4 md:mb-6 leading-relaxed"
        >
          It enables you to reach a global audience, target specific demographics, and measure the success of your campaigns with real-time analytics. By using the power of digital marketing services, you can level the business and reach worldwide. Whether you're a small business owner or an established brand, investing in digital marketing is essential for staying relevant in today's competitive marketplace.
        </p> */}

        {/* Divider */}
        <hr className="my-6 md:my-8 border-gray-300" />

        {/* Section 1: SEO Services */}
        <h2 data-aos="fade-up" className="text-2xl md:text-3xl font-semibold mb-3 md:mb-4">
          
Paid Advertising Channels

        </h2>
        <p data-aos="fade-up" className="text-base md:text-lg mb-4 md:mb-6 leading-relaxed">
        There are many online advertising channels out there but here is an overview of the main ones.
        </p>
        {/* <p data-aos="fade-up" className="text-base md:text-lg mb-4 md:mb-6 leading-relaxed">
          By delivering the best analytical insights and practices, we help you sail through the tides of your online business. Best practices will be adopted through targeted keywords, content strategies, and the social media you use, helping build reputable businesses.
        </p> */}

        {/* Section 2: Social Media Marketing Services */}
        <h2 data-aos="fade-up" className="text-2xl md:text-3xl font-semibold mb-3 md:mb-4">
        GOGGLE ADS 
        </h2>
        <p data-aos="fade-up" className="text-base md:text-lg mb-4 md:mb-6 leading-relaxed">
        previously called AdWords, is the best known and longest standing advertising platform on the internet. Its sophisticated platform allows you to advertise on the Google search engine, YouTube as well as millions of websites and apps that are part of its network. Using machine learning and the data it collects on users, Google matches keywords and people with your ads and they’re quite good at it!
        </p>
        {/* <p data-aos="fade-up" className="text-base md:text-lg mb-4 md:mb-6 leading-relaxed">
          The more you grow your audience using social media, the more profit you will get. With people naturally following and promoting your content, there will be no need for pitching. This approach will help you achieve not just business goals, but also the other unseen benefits that lead to satisfaction and fulfillment.
        </p> */}

        {/* Section 3: Email Marketing Services */}
        <h2 data-aos="fade-up" className="text-2xl md:text-3xl font-semibold mb-3 md:mb-4">
        LINKDIN ADS 
        </h2>
        <p data-aos="fade-up" className="text-base md:text-lg mb-4 md:mb-6 leading-relaxed">
        offers advertising across its platform and is very much focused on professionals. You can target users based on their job title, the industry or company they work for or their interests, combined with geographic and demographic data. The platform mainly uses a Pay-Per-Click mode
        </p>
        {/* <p data-aos="fade-up" className="text-base md:text-lg mb-4 md:mb-6 leading-relaxed">
          Email marketing requires very little upfront investment, as the only startup cost is the price of the email platform you use. It’s a very personal form of communication, as your message sits directly inside the recipient’s inbox, along with messages from friends and colleagues.
        </p> */}

        {/* Section 4: Website Design and Development Services */}
        <h2 data-aos="fade-up" className="text-2xl md:text-3xl font-semibold mb-3 md:mb-4">
        FACEBOOK ADS 
        </h2>
        <p data-aos="fade-up" className="text-base md:text-lg leading-relaxed">
        It provides advertising capabilities on its platforms including Facebook, Messenger and Instagram. You can target users based on a lot of criteria such as their interests, the pages they like and even characteristics about their friends. As other platforms, they mainly use a Pay-Per-Click model based on real-time auctions.
        </p>
        {/* <p data-aos="fade-up" className="text-base md:text-lg leading-relaxed">
          Managing a single responsive site is cost-effective, saving you time and money compared to running separate sites for desktop and mobile. Responsive design keeps your site relevant as new devices are released, ensuring it always looks great. In short, investing in responsive web design is essential for reaching and connecting with your audience effectively.
        </p> */}

        {/* Divider */}
        <hr className="my-6 md:my-8 border-gray-300" />
      </div>
    </div>
  );
};

export default Blog4;
