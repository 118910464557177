import React from "react";

const testimonials = [
  {
    name: "Nancy Arora",
    title: "CEO",
    testimonial:
      "Working with Adbrusher Media has been a game changer for us. Their SEO strategies increased our website traffic by 150% in just three months! The team truly understands our needs and delivers results that exceed our expectations.",
    image: "https://randomuser.me/api/portraits/women/44.jpg",
  },
  {
    name: "Rajesh Chopra",
    title: "Marketing Manager",
    testimonial:
      "The creativity and expertise of this team are unmatched. Their social media campaigns not only boosted our brand visibility but also engaged our audience like never before. We saw a 40% increase in customer interactions within the first month!",
    image: "https://randomuser.me/api/portraits/men/44.jpg",
  },
  {
    name: "Emily Tiphny",
    title: "Owner",
    testimonial:
      "I can’t recommend Adbrusher Media enough! They transformed our online presence through effective content marketing and email campaigns. Our sales have increased significantly, and our customer loyalty has never been stronger.",
    image: "https://randomuser.me/api/portraits/women/45.jpg",
  },
  {
    name: "Mary Alice",
    title: "Founder",
    testimonial:
      "The PPC campaign designed by Adbrusher Media team brought immediate results. We achieved a remarkable ROI and were able to reach our target audience more effectively. Their attention to detail and strategic planning made all the difference.",
    image: "https://randomuser.me/api/portraits/women/46.jpg",
  },
  {
    name: "Saurabh Chawla",
    title: "Director",
    testimonial:
      "From website development to digital marketing strategies, Adbrusher Media has been with us every step of the way. Their dedication and innovative approach have helped us grow our online enrollment by 60%.",
    image: "https://randomuser.me/api/portraits/men/46.jpg",
  },
];

const TestimonialWidget = () => {
  return (
    <section
      className="relative py-10 sm:py-16 lg:py-20 bg-cover bg-center"
      style={{
        backgroundImage:
          "url('https://ik.imagekit.io/7uve7qsipm/customerimage?updatedAt=1727505923400')",
      }}
    >
      {/* White overlay tint */}
      <div className="absolute inset-0 bg-white bg-opacity-70"></div>

      {/* Content */}
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Section heading */}
        <div className="text-left mb-8 sm:mb-10">
          {/* <h2 className="text-sm sm:text-md font-semibold text-teal-500 mb-4">
            T E S T I M O N I A L S
          </h2> */}
          <h1 className="text-3xl sm:text-4xl lg:text-6xl font-bold text-gray-900 mb-4">
            Testimonials
          </h1>
          <p className="text-sm sm:text-lg text-gray-600">
            See what our satisfied customers have to say about us!
          </p>
        </div>

        {/* Testimonial Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-10">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="bg-white border border-gray-200 rounded-lg shadow-md p-4 sm:p-6 transition-transform transform hover:scale-105"
              data-aos="fade-up"
              data-aos-delay={index * 100}
            >
              <div className="flex items-center mb-4">
                <img
                  src={testimonial.image}
                  alt={testimonial.name}
                  className="w-12 sm:w-16 h-12 sm:h-16 rounded-full border-2 border-gray-300 mr-4"
                />
                <div>
                  <h3 className="text-md sm:text-lg font-semibold text-gray-800">
                    {testimonial.name}
                  </h3>
                  <p className="text-sm text-gray-500">{testimonial.title}</p>
                </div>
              </div>
              <div className="relative text-gray-700 text-sm sm:text-md italic">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute w-6 sm:w-8 h-6 sm:h-8 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  style={{ left: "-20px", top: "10px" }}
                >
                  <path d="M6.293 7.293a1 1 0 011.414 0L10 9.586V4a1 1 0 112 0v6a1 1 0 01-.293.707l-3 3a1 1 0 01-1.414-1.414L10.586 11H8a4 4 0 00-4 4v1a1 1 0 11-2 0v-1a6 6 0 016-6h2.586l-.293-.293a1 1 0 010-1.414z" />
                </svg>
                "{testimonial.testimonial}"
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialWidget;
