import React from "react";
import Hero from "../HomeComponent/Hero";
import OurWork from "../Components/OurWork";
import Blogs from "../Components/Blogs";
import DiscountModal from "../Components/DiscountModal";
import PreNavbarModal from "../Components/PreNavbarModal";
import TestimonialWidget from "../Components/TesimonialWidget";
import ServicesWidget from "../Components/ServicesWidget";
import AboutWidget from "../Components/AboutWidget";
import ChooseUs from "../Components/ChooseUs";

const Home = () => {
  return (
    <div className="">
      {" "}
      <Hero />
      <AboutWidget />
      <ChooseUs />
      <ServicesWidget />
      <OurWork />
      <TestimonialWidget />
    </div>
  );
};

export default Home;
